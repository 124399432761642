@import './guideVar.scss';

.CustomPrompt {
    &__Container {
        width: 100%;
        position: fixed;
        top: 12px;
        right: 32px;
        width: 24px;
        height: 24px;
        border-radius: 4px;
        overflow: hidden;
    }

    &__DockContainer {
        width: 100%;
        min-width: 505px;
        height: 100%;
        border-left: 1px solid $color-gray-200;
    }

    &__Button {
        &__deploy {
            width: 92px;
            height: 37px;
            border-radius: 10px;
            background: $color-prim-800;
            border: none;

            &__disabled {
                @extend .CustomPrompt__Button__deploy;
                background: #e1e1e1;
                cursor: not-allowed;
            }
        }
    }
}