@charset "UTF-8";
@font-face {
  font-family: "pretendard";
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/Pretendard-Light.woff") format("woff"); /* light */
}
@font-face {
  font-family: "pretendard";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/Pretendard-Regular.woff") format("woff"); /* regular */
}
@font-face {
  font-family: "pretendard";
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/Pretendard-SemiBold.woff") format("woff"); /* semi bold */
}
@font-face {
  font-family: "pretendard";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/Pretendard-Bold.woff") format("woff"); /* regular */
}
html {
  -webkit-text-size-adjust: none;
}

#root {
  --vh: 100%;
  height: 100%;
}

body, html {
  width: 100vw;
  height: 100vh;
  font-family: "pretendard";
  margin: 0;
  overflow-x: hidden;
  color: #222222;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body *, html * {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input::-moz-placeholder, textarea::-moz-placeholder {
  margin: 0;
  font-family: "pretendard", sans-serif;
}

h1, h2, h3, h4, h5, h6, p, .label, span, input::placeholder, textarea::placeholder {
  margin: 0;
  font-family: "pretendard", sans-serif;
}

.h1 {
  font-family: "pretendard", sans-serif;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -1px;
  font-weight: 700;
}

.h2 {
  font-family: "pretendard", sans-serif;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.75px;
  font-weight: 700;
}

.h3 {
  font-family: "pretendard", sans-serif;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: -0.625px;
  font-weight: 700;
}

.h4 {
  font-family: "pretendard", sans-serif;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.5px;
  font-weight: 700;
}

.h5 {
  font-family: "pretendard", sans-serif;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.5px;
  font-weight: 400;
}

.h6 {
  font-family: "pretendard", sans-serif;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: -0.375px;
  font-weight: 700;
}

.h7 {
  font-family: "pretendard", sans-serif;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: -0.375px;
  font-weight: 400;
}

.h8 {
  font-family: "pretendard", sans-serif;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.35px;
  font-weight: 700;
}

.h9 {
  font-family: "pretendard", sans-serif;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.35px;
  font-weight: 600;
}

.h10 {
  font-family: "pretendard", sans-serif;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.35px;
  font-weight: 400;
}

.h11 {
  font-family: "pretendard", sans-serif;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.325px;
  font-weight: 700;
}

.h12 {
  font-family: "pretendard", sans-serif;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.3px;
  font-weight: 600;
}

.h13 {
  font-family: "pretendard", sans-serif;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.3px;
  font-weight: 400;
}

.h14 {
  font-family: "pretendard", sans-serif;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: -0.275px;
  font-weight: 600;
}

button, a {
  cursor: pointer;
  -webkit-text-fill-color: inherit;
  color: inherit;
}

a {
  text-decoration: none;
}
a:-webkit-any-link {
  color: inherit;
}

ul, ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.fc-prim-800 {
  color: #154cca;
}

.fc-prim-900 {
  color: #103b9c;
}

.fc-prim-700 {
  color: #0025E4;
}

.fc-prim-300 {
  color: #AAB8ED;
}

.fc-prim-200 {
  color: #bbc8e5;
}

.fc-prim-150 {
  color: #E0E5F8;
}

.fc-prim-100 {
  color: #e0e6f3;
}

.fc-bg-100 {
  color: #f7f7f7;
}

.fc-gray-900 {
  color: #000000;
}

.fc-gray-800 {
  color: #222222;
}

.fc-gray-700 {
  color: #444444;
}

.fc-gray-600 {
  color: #666666;
}

.fc-gray-500 {
  color: #999999;
}

.fc-gray-400 {
  color: #c0c0c0;
}

.fc-gray-300 {
  color: #e1e1e1;
}

.fc-gray-200 {
  color: #efefef;
}

.fc-gray-100 {
  color: #f6f6f6;
}

.fc-white {
  color: #ffffff;
}

.fc-positive-900 {
  color: #0cbd48;
}

.fc-positive-300 {
  color: #d6eddc;
}

.fc-warning {
  color: #ff5555;
}

.fc-review {
  color: #ffc738;
}

.fc-emphasis {
  color: #ec3e48;
}

.fc-text {
  color: #222222;
}

.spacing-2 {
  width: 2px;
  min-width: 2px;
  height: 2px;
  min-height: 2px;
}

.spacing-4 {
  width: 4px;
  min-width: 4px;
  height: 4px;
  min-height: 4px;
}

.spacing-6 {
  width: 6px;
  min-width: 6px;
  height: 6px;
  min-height: 6px;
}

.spacing-8 {
  width: 8px;
  min-width: 8px;
  height: 8px;
  min-height: 8px;
}

.spacing-12 {
  width: 12px;
  min-width: 12px;
  height: 12px;
  min-height: 12px;
}

.spacing-16 {
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
}

.spacing-20 {
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
}

.spacing-24 {
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
}

.spacing-32 {
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
}

.spacing-40 {
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
}

.spacing-48 {
  width: 48px;
  min-width: 48px;
  height: 48px;
  min-height: 48px;
}

.spacing-56 {
  width: 56px;
  min-width: 56px;
  height: 56px;
  min-height: 56px;
}

.spacing-72 {
  width: 72px;
  min-width: 72px;
  height: 72px;
  min-height: 72px;
}

.spacing-80 {
  width: 80px;
  min-width: 80px;
  height: 80px;
  min-height: 80px;
}

.hide {
  display: none;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.pointer {
  cursor: pointer;
}

.default-cursor {
  cursor: default;
}

.flex {
  width: 100%;
  height: 100%;
  display: flex;
}

.flexcol {
  flex-direction: column;
}

.f-center {
  align-items: center;
  justify-content: center;
}

.f-left {
  align-items: flex-start;
}

.f-right {
  align-items: flex-end;
}

.f-between {
  align-items: center;
  justify-content: space-between;
}

.t-center-w100 {
  width: 100%;
  text-align: center;
}

.w-fit-content {
  width: -moz-fit-content;
  width: fit-content;
}

.w-100 {
  width: 100%;
}

/* width와 height는 스크롤바의 크기를 지정합니다. */
/* thumb-radius는 스크롤바 위아래 버튼의 크기를 지정합니다. */
/* track-color는 스크롤바 바탕 색상을 지정합니다. */
/* thumb-color는 스크롤바 버튼 색상을 지정합니다. */
/* thumb-active-color는 스크롤바 버튼을 클릭할 때 나타나는 색상을 지정합니다. */
::-webkit-scrollbar {
  width: 0px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb:active {
  background-color: rgba(0, 0, 0, 0);
}

g:focus,
path:focus {
  outline: none !important;
}/*# sourceMappingURL=global.css.map */