.Login__Container {
  width: 100%;
  height: 100%;
  display: flex;
}
.Login__Left {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 60px;
  padding-right: 0;
  background: #fff;
}
@media screen and (max-width: 400px) {
  .Login__Left {
    display: none;
  }
}
.Login__ImageWrap {
  width: 100%;
  height: 100%;
  background: #1d1d1d;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}
.Login__Image {
  width: 100%;
  position: absolute;
  bottom: 0;
  min-width: 684px;
}
.Login__Right {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1240px) {
  .Login__Right {
    flex: 0 400px;
  }
}
.Login__InputWrap {
  width: 100%;
  max-width: 320px;
  height: -moz-fit-content;
  height: fit-content;
}
@media screen and (max-width: 1240px) {
  .Login__InputWrap {
    max-width: 300px;
  }
}
.Login__Input {
  width: 100%;
  height: 100%;
  background: #f6f6f6;
  border: none;
  border-radius: 10px;
  padding: 16px;
}
.Login__BtnWrap {
  width: 320px;
  height: 51px;
}
.Login__DemoButton {
  width: 100%;
  max-width: 320px;
  height: -moz-fit-content;
  height: fit-content;
  border: none;
  border-radius: 10px;
  height: 51px;
  border: 1px solid #e1e1e1;
  background: #ffffff;
}

.Signup__Input, .Signup__Input__error {
  width: 100%;
  height: 48px;
  background: #f6f6f6;
  border: none;
  border-radius: 10px;
  padding: 14px 16px;
}
.Signup__Input__error:focus-visible {
  outline: 2px solid #ff5555;
}

.InputField__LabelWrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.InputField__SubFunc {
  width: -moz-fit-content;
  width: fit-content;
  background: none;
  border: none;
}
.InputField__PwInputWrap {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  position: relative;
}
.InputField__VisibilityIcon {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 16px;
  cursor: pointer;
}
.InputField__CopyField {
  display: flex;
  height: 48px;
  padding: 14px 16px;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  background: #efefef;
  justify-content: space-between;
}
.InputField__ErrorWrap {
  width: 100%;
  display: flex;
  margin-top: 6px;
}/*# sourceMappingURL=login.module.css.map */