@import './guideVar.scss';

.AgentCustom {
    &__Container {
        width: 100%;
        height: fit-content;
        background: $color-white;
        // border: 1px solid $color-gray-300;
        border-radius: 10px;
        // padding: $spacing32;
        position: relative;
    }
    
    &__Wrap {
        width: 100%;
        display: flex;
        //gap: $spacing32;
        justify-content: center;
    }

    &__Left {
        min-width: 455px;
        height: 735px;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
    }

    &__Right {
        width: 100%;
        max-width: 529px;
        min-width: 0;
        height: fit-content;
        border-radius: 10px;
        overflow: visible;
        position: relative;
    }

    &__Hr {
        width: 100%;
        border: 1px solid $color-gray-200;
    }

    &__ButtonWrap {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: flex-end;

        &__col {
            @extend .AgentCustom__ButtonWrap;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
        }

        &__betaFeatures {
            @extend .AgentCustom__ButtonWrap;
            padding: $spacing48 0 $spacing12 0;
        }
    }

    &__Button {
        width: fit-content;
        height: fit-content;
        padding: $spacing12 20px;
        background: $color-prim-800;
        border: none;
        border-radius: 10px;

        &__Inactive {
            @extend .AgentCustom__Button;
            background: $color-gray-200;
        }
    }
}