.Main__Background {
  width: 100%;
  height: 100vh;
  margin: auto;
  background: #ffffff;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
}
.Main__Wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: absolute;
}
.Main__SideBar__Wrap {
  width: 230px;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  overflow-y: auto;
  background: #f6f6f6;
  border-right: 1px solid #e1e1e1;
  /* Firefox-specific scrollbar styling */
  scrollbar-width: thin;
  scrollbar-color: #999999 transparent;
}
.Main__SideBar__Wrap::-webkit-scrollbar {
  width: 11px;
}
.Main__SideBar__Wrap::-webkit-scrollbar-thumb {
  border: 2px solid #f6f6f6;
  background: #999999;
  border-radius: 5px;
}
.Main__SideBar__Wrap::-webkit-scrollbar-track {
  background: transparent;
}
.Main__SideBar {
  width: 230px;
  min-height: 669px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
  padding: 0 16px 24px 16px;
}
.Main__SideBar__LogoBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: fit-content;
  padding: 12px 16px;
}
.Main__SideBar__TopMenuBox {
  width: 100%;
  height: fit-content;
}
.Main__SideBar__BottomMenuBox {
  width: 100%;
  height: fit-content;
  border-top: 1px solid #e1e1e1;
  padding-top: 16px;
}
.Main__List {
  display: flex;
  flex-direction: column;
}
.Main__List__MenuInput {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 800;
  margin: 4px 0;
  align-items: center;
}
.Main__List__MenuInput[type=radio] {
  appearance: none;
}
.Main__List__MenuInput[type=radio]:checked + label {
  background: #e1e1e1;
  border: none;
  border-radius: 10px;
}
.Main__List__MenuInput[type=radio]:checked + label p {
  color: #222222;
}
.Main__List__MenuInput[type=radio]:checked + label img {
  filter: brightness(0);
}
.Main__List__MenuLabel, .Main__List__MenuLabel__selected {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border: none;
  border-radius: 10px;
}
.Main__List__MenuLabel__selected {
  background: #e1e1e1;
}
.Main__List__MenuLabel__selected p {
  color: #222222;
}
.Main__List__SubLabel__Wrap {
  width: 100%;
  height: 40px;
  padding: 16px 8px 16px 32px;
}
.Main__List__SubLabel__Content, .Main__List__SubLabel__Content__selected {
  width: 100%;
  padding: 16px 8px 16px 56px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: none;
  border-radius: 10px;
}
.Main__List__SubLabel__Content__selected {
  background: #e0e6f3;
}
.Main__List__SubLabel__Content__selected p {
  color: #154cca;
}
.Main__Container {
  width: 100%;
  position: relative;
}
.Main__OutletWrap {
  width: calc(100% - 230px);
  height: calc(100% - 50px);
  overflow-y: scroll;
  position: relative;
  top: 0;
  left: 230px;
}
.Main__Outlet {
  width: calc(100% - 272px);
  overflow-y: scroll;
}

.TopNav__Container {
  width: calc(100% - 230px);
  height: 50px;
  position: relative;
  top: 0;
  left: 230px;
  min-height: 40px;
  min-width: 1010px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #efefef;
  padding: 15px 32px;
}
.TopNav__Wrap {
  width: auto;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

