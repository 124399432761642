.General__Container {
  width: 100%;
  max-width: 1044px;
  min-width: 946px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  position: relative;
}
.General__MinHeightWrap {
  width: 100%;
  height: fit-content;
  min-height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.General__GeneralSetting {
  width: 100%;
  position: relative;
}

.Toast__Block {
  position: fixed;
  bottom: 80px;
  display: flex;
  width: 100%;
  justify-content: center;
  z-index: 1000;
}

