.Analytics__Container {
  width: 100%;
  height: 100%;
  min-width: 954px;
  max-width: 1044px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding: 32px;
}
.Analytics__Download {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: fit-content;
  height: 100%;
}
.Analytics__Download__Btn {
  display: flex;
  width: fit-content;
  height: fit-content;
  align-items: center;
  padding: 11px 10px;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #e1e1e1;
  background: #ffffff;
  cursor: default;
}
.Analytics__Download__Btn:hover {
  background: #f6f6f6;
}
.Analytics__Download {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: fit-content;
  height: 100%;
}
.Analytics__Download__Btn {
  display: flex;
  width: fit-content;
  height: fit-content;
  align-items: center;
  padding: 11px 10px;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #e1e1e1;
  background: #ffffff;
  cursor: default;
}
.Analytics__Download__Btn:hover {
  background: #f6f6f6;
}
.Analytics__Contents {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #ffffff;
  border: 1px solid #efefef;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 32px;
}
.Analytics__BreakLine {
  width: 100%;
  height: 1px;
  background: #e1e1e1;
  border: 0;
  margin: 0;
}
.Analytics__Table {
  border-collapse: collapse;
  width: 100%;
}
.Analytics__Table__Wrap {
  width: 100%;
  height: fit-content;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}
.Analytics__Table__Head, .Analytics__Table__Head__gray {
  word-break: keep-all;
  padding: 16px 8px;
  text-align: center;
  background: #f2f4fa;
  border-bottom: 2px solid #e1e1e1;
}
.Analytics__Table__Head__gray {
  background: #f6f6f6;
}
.Analytics__Table__Body, .Analytics__Table__Body__underline {
  word-break: break-all;
  padding: 24px 8px;
  text-align: center;
}
.Analytics__Table__Body__underline {
  border-bottom: 1px solid #e1e1e1;
}
.Analytics__Table__Detail {
  padding: 16px 8px;
  text-align: left;
  background: #f6f6f6;
}
.Analytics__Dimmed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.Analytics__TopChart__PieWrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Analytics__TopChart__InfoBox {
  width: 100%;
  background: #E0E5F8;
  border: 1px solid #AAB8ED;
  border-radius: 10px;
  display: flex;
  padding: 16px;
  align-items: center;
}

