//max width condition
$max-width-1440: 1440px;
$max-width-1240: 1240px;

// spacing variables
$spacing2: 2px;
$spacing4: 4px;
$spacing6: 6px;
$spacing8: 8px;
$spacing12: 12px;
$spacing14: 14px;
$spacing16: 16px;
$spacing20: 20px;
$spacing24: 24px;
$spacing32: 32px;
$spacing40: 40px;
$spacing48: 48px;
$spacing56: 56px;
$spacing72: 72px;
$spacing80: 80px;

// color variables
$color-prim-800: #154cca;
$color-prim-900: #103b9c;
$color-prim-700: #0025E4;
$color-prim-300: #AAB8ED;
$color-prim-200: #bbc8e5;
$color-prim-150: #E0E5F8;
$color-prim-100: #e0e6f3;
$color-prim-50: #f2f4fa;
$color-bg-100: #f7f7f7;
$color-gray-900: #000000;
$color-gray-800: #222222;
$color-gray-700: #444444;
$color-gray-600: #666666;
$color-gray-500: #999999;
$color-gray-400: #c0c0c0;
$color-gray-300: #e1e1e1;
$color-gray-200: #efefef;
$color-gray-100: #f6f6f6;
$color-white: #ffffff;

// role color
$color-positive-900: #0cbd48;
$color-positive-300: #d6eddc;
$color-warning: #ff5555;
$color-review: #ffc738;
$color-emphasis: #ec3e48;

$color-text: #222222;

// information color
$color-green-900: #0cbd48;
$color-green-300: #d6eddc;
$color-skyblue-900: #49ade5;
$color-skyblue-300: #c0e3f6;
$color-pink-900: #e24eb0;
$color-pink-300: #f7cae8;

// additional color
$color-purple-900: #3c1490;
$color-purple-800: #6225c6;
$color-purple-700: #8c7dc5;
$color-purple-600: #ae95d8;
$color-purple-200: #cabcd4;
$color-tealgreen-900: #004b68;
$color-tealgreen-800: #346e82;
$color-tealgreen-700: #1c89a1;
$color-tealgreen-600: #6796a6;
$color-tealgreen-500: #43d9be;

//font-weight
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;