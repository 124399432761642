@import './guideVar.scss';

.General {

    &__Container {
        width: 100%;
        max-width: 1044px;
        min-width: 946px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: $spacing32;
        position: relative;
    }

    &__MinHeightWrap {
        width: 100%;
        height: fit-content;
        min-height: 800px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__GeneralSetting {
        width: 100%;
        position: relative;
    }
}

.Toast {
    &__Block {
        position: fixed;
        bottom: 80px;
        display: flex;
        width: 100%;
        justify-content: center;
        z-index: 1000;
    }
}