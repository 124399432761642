@import './guideVar.scss';

.Mobile {

    &__Container {
        width: 100%;
        overflow-y: scroll;
        display: flex;
        justify-content: center;
        position: relative;
    }

    &__Background {
        width: 320px;
        height: auto;
    }

    &__Fb {
        width: 208px; 
        height: 60px; 
        position: fixed; 
        bottom: calc(var(--posY) * 1px); 
        left: calc(50% + (320px/2) - (var(--posX) * 1px)); 
        transform: translateX(-100%);
        z-index: 5;
    }
    
}

.Desktop {

    &__Container {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
    }

    &__Background {
        width: 100%;
        height: auto;
    }

    &__Fb {
        width: 380px;
        height: 80px;
        position: absolute;
        bottom: calc(var(--posY) * 1px);
        right: calc(var(--posX) * 1px);
        z-index: 5;
    }
}