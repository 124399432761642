.Experiment__Container {
  width: 100%;
  max-width: 1044px;
  min-width: 946px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  position: relative;
}
.Experiment__MinHeightWrap {
  width: 100%;
  height: fit-content;
  min-height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Experiment__Wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.Toast__Block {
  position: absolute;
  bottom: 80px;
  transform: translateY(-100%);
  display: flex;
  width: 100%;
  justify-content: center;
}

.BetaFeatures__Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}
.BetaFeatures__Wrap {
  display: flex;
  padding: 24px 0;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.BetaFeatures__TextWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.BetaFeatures__SwitchImg, .BetaFeatures__SwitchImg__disabled, .BetaFeatures__SwitchImg__active {
  width: 50px;
  height: 30px;
}
.BetaFeatures__SwitchImg__active {
  cursor: pointer;
}
.BetaFeatures__SwitchImg__disabled {
  cursor: default;
}
.BetaFeatures__BtnWrap {
  display: flex;
  width: 169px;
  height: 44px;
}

