@import './guideVar.scss';

.Login {
    &__Container {
        width: 100%;
        height: 100%;
        display: flex;
        
    }
    
    &__Left {
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        padding: 60px;
        padding-right: 0;
        background: #fff;

        @media screen and (max-width: 400px) {
            display: none;
        }
    }

    &__ImageWrap {
        width: 100%;
        height: 100%;
        background: #1d1d1d;
        border-radius: 20px;
        position: relative;
        overflow: hidden;
    }

    &__Image {
        width: 100%;
        position: absolute;
        bottom: 0;
        min-width: 684px;
    }

    &__Right {
        width: 40%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // box-shadow: 0 4px 20px 0 rgba($color: #000000, $alpha: 0.1);

        @media screen and (max-width: 1240px) {
            flex: 0 400px;
        }
    }

    &__InputWrap {
        width: 100%;
        max-width: 320px;
        height: fit-content;

        @media screen and (max-width: 1240px) {
            max-width: 300px;
        }
    }

    &__Input {
        width: 100%;
        height: 100%;
        background: $color-gray-100;
        border: none;
        border-radius: 10px;
        padding: $spacing16;
    }

    &__BtnWrap {
        width: 320px;
        height: 51px;
    }

    &__DemoButton {
        width: 100%;
        max-width: 320px;
        height: fit-content;
        border: none;
        border-radius: 10px;
        height: 51px;
        border: 1px solid $color-gray-300;
        background: $color-white;
        // box-shadow: 0 4px 20px 0 rgba($color: #000000, $alpha: 0.1);
    }
}

.Signup {
    &__Input {
        width: 100%; 
        height: 48px; 
        background: #f6f6f6; 
        border: none; 
        border-radius: 10px; 
        padding: 14px 16px;
        // border: 2px solid #ec3e48;

        &__error {
            @extend .Signup__Input;

            &:focus-visible {
                outline: 2px solid $color-warning;
            }
        }
    }
}

.InputField {
    &__LabelWrap {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    &__SubFunc {
        width: fit-content;
        background: none;
        border: none;
    }

    &__PwInputWrap {
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
        position: relative;
    }

    &__VisibilityIcon {
        width: 20px;
        height: 20px;
        position: absolute;
        right: 16px;
        cursor: pointer;
    }

    &__CopyField {
        display: flex;
        height: 48px;
        padding: 14px 16px;
        align-items: center;
        width: 100%;
        border-radius: 10px;
        background: $color-gray-200;
        justify-content: space-between;
    }
    &__ErrorWrap {
        width: 100%;
        display: flex;
        margin-top: 6px;
    }
}