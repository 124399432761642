@import './guideVar.scss';

.Modal {
    &__Background {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
    }

    &__Wrap {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

    }
}

.Toast {
    &__Container {
        width: 100%;
        height: fit-content;
        position: fixed;
        bottom: 48;
        left: 0;
        z-index: 999;
    }

    &__Block {
        width: 100%;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.DesignMode {
    &__Wrap {
        width: 100%;
        height: fit-content;
        max-width: 1044px;
        min-width: 946px;
        margin-left: auto;
        margin-right: auto;
        padding: 32px;
    }

    &__Container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__InterfaceSetting {
        width: 100%;
        position: relative;
    }
}