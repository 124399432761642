.ChatPreview__Container {
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 42px 58px;
}
.ChatPreview__Block {
  width: 390px;
  height: 700px;
  min-height: 700px;
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
  background-image: url("/public/img/chatPreview/preview-block.png");
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ChatPreview__Block__button {
  width: 262px;
  height: 52px;
  border: none;
  background: #e0e6f3;
  border-radius: 8px;
}
.ChatPreview__Block__textWrap {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.ChatPreview__Block__iframeWrap, .ChatPreview__Block__iframeWrap__mobile {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
}
.ChatPreview__Block__iframeWrap__mobile {
  width: 390px;
  min-height: 560px;
  max-height: 754px;
}
.ChatPreview__Block__iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.AgentToggle__Container {
  width: 100%;
  height: 100%;
  position: relative;
  flex-grow: 1;
}
.AgentToggle__Wrap {
  position: absolute;
  top: 8px;
  right: 8px;
  width: -moz-fit-content;
  width: fit-content;
  height: 37px;
  background: #efefef;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 4px;
}
.AgentToggle__active {
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #ffffff;
  cursor: pointer;
}
.AgentToggle__inactive {
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #efefef;
  cursor: pointer;
}
.AgentToggle__inactive p {
  color: #999999;
}/*# sourceMappingURL=chatpreview.module.css.map */