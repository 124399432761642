
.react-datepicker-wrapper {
    align-items: center;
    display: flex;
    height: 70px;
    justify-content: center;
    width: 265px;
}

.react-datepicker__input-container {
    width: 100%;
    padding: 0 !important;
}

.react-datepicker__triangle {
    visibility: hidden;
}

.react-datepicker {
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    border: 1px solid #F6F6F6;
    border-radius: 16px;
    padding: 24px 32px;
}

.react-datepicker-popper {
    top: -18px !important;
    left: 36px !important;
}

.react-datepicker__header {
    border-bottom: none;
    padding-bottom: 0;
    background: #fff;
}

.react-datepicker__current-month {
    color: #222;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Pretendard', Arial, sans-serif;
    padding-bottom: 8px;
}

.react-datepicker__navigation {
    top: 30px;
}

.react-datepicker__navigation--previous {
    left: 40px;
}

.react-datepicker__navigation--next {
    right: 40px;
}

.react-datepicker__day-names {
    padding-top: 8px;
}

.react-datepicker__day-name {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    border: 2px solid #fff;
    background: #FFF;
    color: #C0C0C0;
    font-weight: 700;
}

.react-datepicker__day {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    border: 2px solid #fff;
    background: #FFF;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Pretendard', Arial, sans-serif;
}

.react-datepicker__day--today {
    font-weight: 700;
}

.react-datepicker__day--outside-month {
    color: #C0C0C0;
}

.react-datepicker__day--in-selecting-range {
    width: 32px;
    height: 32px;
    border-radius: 0;
    box-sizing: border-box;
    border-left: 2px transparent;
    border-right: 2px transparent;
    border-top: 2px solid #154CCA;
    border-bottom: 2px solid #154CCA;
    background: #FFF;
    background-color: #fff !important;
    color: #222;
}

.react-datepicker__day--in-range {
    width: 32px;
    height: 32px;
    border-radius: 0;
    box-sizing: border-box;
    border-left: 2px transparent;
    border-right: 2px transparent;
    border-top: 2px solid #154CCA;
    border-bottom: 2px solid #154CCA;
    background: #FFF;
    color: #222;
}

.react-datepicker__day--selected, .react-datepicker__day--range-end {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    border: 2px solid #154CCA;
    background-color: #154CCA !important;
    color: #fff !important; 
    font-size: 15px;
    font-weight: 700;
    font-family: 'Pretendard', Arial, sans-serif;
}

.react-datepicker__day:hover, .react-datepicker__day--in-range:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--selected:hover {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background: #F6F6F6;
    border: 2px solid #154CCA;
    color: #222;
}
