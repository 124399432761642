@import './guideVar.scss';

.Experiment {

    &__Container {
        width: 100%;
        max-width: 1044px;
        min-width: 946px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: $spacing32;
        position: relative;
    }

    &__MinHeightWrap {
        width: 100%;
        height: fit-content;
        min-height: 800px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__Wrap {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }
}

.Toast {
    &__Block {
        position: absolute;
        bottom: 80px;
        transform: translateY(-100%);
        display: flex;
        width: 100%;
        justify-content: center;
    }
}

.BetaFeatures {

    &__Container {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: stretch;
    }

    &__Wrap {
        display: flex;
        padding: 24px 0;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
    }

    &__TextWrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__SwitchImg {
        width: 50px;
        height: 30px;
        
        &__active {
            @extend .BetaFeatures__SwitchImg;
            cursor: pointer;
        }

        &__disabled {
            @extend .BetaFeatures__SwitchImg;
            cursor: default;
        }
    }

    &__BtnWrap {
        display: flex;
        width: 169px;
        height: 44px;
    }
}