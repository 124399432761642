@import './guideVar.scss';

.Plans {
    &__Container {
        width: fit-content;
        min-width: fit-content;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: $spacing32;
    }

    &__Plan {
        width: 946px;
        max-width: 946px;
        min-width: 946px;
        background: $color-white;
        border-radius: 10px;
        border: 1px solid $color-gray-200;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.10);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__PlanTitle {
        width: 100%;
        display: flex;
        padding: 80px 0px 40px 0px;
        flex-direction: column;
        align-items: center;
        align-self: stretch;
    }

    &__PlanBody {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: stretch;
    }
}

.PlanToggle {
    &__Wrap {
        display: flex;
        width: fit-content;
        position: relative;
    }

    &__Switch {
        display: flex;
        flex-direction: row;
        padding: $spacing12 $spacing20;
        align-items: center;
        gap: $spacing8;
        border-radius: 999px;
        background: $color-gray-100;
    }

    &__DiscountLabel {
        display: flex;
        padding: $spacing4 $spacing6;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: -53px;
        top: 14.5px;
        border-radius: 6px;
        background: $color-prim-50;
    }
}

.PlanCards {
    &__Container {
        display: flex;
        width: 100%;
        padding: 0px $spacing32;
        align-items: center;
        gap: $spacing8;
    }

    &__CateCard {
        display: flex;
        width: 25%;
        padding: $spacing24 $spacing16;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
    }

    &__Card {
        display: flex;
        width: 25%;
        padding: 24px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: $spacing16;
        border-radius: 10px;
        border: 1px solid $color-gray-300;
    }

    &__PlanInfo {
        &__Wrap {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: $spacing20;
            align-self: stretch;
        }

        &__Description {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
        }

        &__Name {
            display: flex;
            height: 24px;
            justify-content: space-between;
            align-items: flex-start;
            align-self: stretch;
        }

        &__Details {
            display: flex;
            align-items: flex-end;
            gap: $spacing4;
        }
    }

    &__PlanCta {
        display: flex;
        padding: 10px 0px;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        border-radius: 10px;
        background: $color-gray-800;
        cursor: pointer;

        &:hover {
            background: $color-gray-600;
        }
    }
}

.PlanTable {

    &__Container {
    display: flex;
    padding: $spacing32 $spacing32 64px $spacing32;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    }

    &__Table {

        &__Wrap {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
        }

        &__Title {
            display: flex;
            padding: $spacing16;
            align-items: center;
            align-self: stretch;
            border-radius: 10px;
            background: $color-gray-200;
        }
    }

    &__SubTable {

        &__Wrap {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            align-self: stretch;
        }

        &__Title {
            display: flex;
            padding: $spacing16;
            align-items: center;
            align-self: stretch;
            border-bottom: 1px solid $color-gray-300;

            &__default{
                @extend .PlanTable__SubTable__Title;
            }

            &__topBorder {
                @extend .PlanTable__SubTable__Title;
                border-top: 1px solid $color-gray-300;
            }
        }
        
        &__Columns {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
        }

        &__Items {
            display: flex;
            width: 100%;
            align-items: center;
            gap: $spacing8;
        }

        &__Row {
            display: flex;
            width: 25%;
            height: 51px;
            padding: $spacing16;
            align-items: center;
            border-right: 1px solid $color-gray-300;
        }

        &__Item {
            display: flex;
            width: 25%;
            height: 51px;
            justify-content: center;
            align-items: center;
        }
    }

}

.CtaModal {
    &__Overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.4);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    &__Content {
        width: 33vw;
        height: 80vh;
        background-color: $color-white;
        border-radius: 10px;
        overflow: hidden;
        min-width: 480px;
    }
    
    &__Iframe {
        width: 100%;
        height: 100%;
        border: none;
        background-color: $color-white;
        display: block;
        min-width: 480px;
    }
}

.selectedPlan {
    &__title {
        background: $color-gray-100;
    }

    &__item {
        background: $color-gray-100;
    }

    &__cta {
        background: $color-gray-300;
        cursor: default;

        &:hover {
            background: $color-gray-300;
        }

    }
}

.Checkout {

    &__Wrap {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px;
        overflow: auto;
    }

    &__Container {
        width: 100%;
        max-width: 540px;
    }
    
    &__BtnWrap {
        width: 100%;
        padding: 0 24px;
    }

    &__Btn {
        width: 100%;
        padding: 11px 22px;
        border: none;
        border-radius:  8px;
        background-color: #f2f4f6;
        color: #4e5968;
        font-weight: 600;
        font-size: 17px;
        cursor: pointer;

        &__primary {
            @extend .Checkout__Btn;
            background-color: #3282f6;
            color: #f9fcff;
        }
    }

    &__Title {
        margin-top: 32px;
        margin-bottom: 0;
        color: #191f28;
        font-weight: bold;
        font-size: 24px;
    }

    &__Description {
        margin-top: 8px;
        color: #4e5968;
        font-size: 17px;
        font-weight: 500;
    }

    &__ResponseSection {
        width: 100%;
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        font-size: 20px;
    }

    &__ResponseLabel {
        font-weight: 600;
        color: #333d48;
        font-size: 17px;
    }

    &__ResponseText {
        font-weight: 500;
        color: #4e5968;
        font-size: 17px;
        padding-left: 16px;
        word-break: break-word;
        text-align: right;
    }

    &__ButtonGroup {
        width: 100%;
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 16px;
    }
}

.Success {
    &__Confirmed {
        
        &__Wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 72px;
            width: 100%;
            max-width: 540px;
        }
    }

    &__Loading {
        &__Wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 72px;
            height: 400px;
            justify-content: space-between;
            width: 100%;
            max-width: 540px;
        }
    }
}

.Fail {
    &__Wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 540px;
    }
}