.Toast__Container {
  width: fit-content;
  height: fit-content;
}
.Toast__Background {
  width: 100%;
  height: 100%;
  background: #222222;
  padding: 16px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}
.Toast__Button {
  width: 68px;
  height: 34px;
  background: #ffffff;
  border: none;
  border-radius: 10px;
}

.CustomTooltip__Wrap {
  position: relative;
}
.CustomTooltip__Icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CustomTooltip__TipBox {
  width: max-content;
  height: auto;
  max-width: 208px;
  padding: 14px;
  background: #222222;
  z-index: 9999;
  border-radius: 10px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.25);
  position: fixed;
  top: calc(var(--rect-bottom) * 1px);
  left: calc(var(--rect-left) * 1px + var(--rect-width) * 1px / 2);
  transform: translateX(-50%);
}
.CustomTooltip__TipText {
  text-align: start;
  white-space: pre-wrap;
}
.CustomTooltip__RefreshWrap {
  position: relative;
  width: 28px;
  height: 28px;
}
.CustomTooltip__RefreshTipBox {
  width: max-content;
  height: auto;
  max-width: 208px;
  padding: 8px 10px;
  background: #222222;
  position: absolute;
  bottom: 36px;
  transform: translateX(calc(-50% + 12px));
  z-index: 9999;
  border-radius: 10px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.25);
}

.Button, .Button__cancel, .Button__secondary, .Button__primary {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
}
.Button__primary {
  width: 100%;
  height: 100%;
  background: #154cca;
  border: none;
  border-radius: 10px;
}
.Button__secondary {
  width: 100%;
  height: 100%;
  background: #c0c0c0;
  border: none;
  border-radius: 10px;
}
.Button__cancel {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #154cca;
  border-radius: 10px;
}

.Flex, .Flex__VCenter, .Flex__HCenter, .Flex__HLeft, .Flex__HRight {
  width: 100%;
  height: fit-content;
  display: flex;
}
.Flex__HCenter, .Flex__HLeft, .Flex__HRight {
  align-items: center;
  justify-content: center;
}
.Flex__HRight {
  justify-content: flex-end;
}
.Flex__HLeft {
  justify-content: flex-start;
}
.Flex__VCenter {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Break__Hr {
  --width: 100%;
  --thickness: 1px;
  --color: #e1e1e1;
  width: var(--width);
  height: 1px;
  border: var(--thickness) solid var(--color);
}

.Preview__Container {
  width: 455px;
  min-width: 375px;
  height: 100%;
  background: #efefef;
  display: flex;
  align-items: end;
  justify-content: center;
  border-radius: 20px;
}
.Preview__Wrap {
  width: 375px;
  height: 679px;
  background: #ffffff;
  border-radius: 30px 30px 0 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
}
.Preview__Header {
  width: 100%;
  height: 44px;
  background: #ffffff;
}
.Preview__Display {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  position: relative;
  z-index: 5;
}
.Preview__Display__Divider {
  width: 100%;
  height: 6px;
  background: #efefef;
  border-bottom: 1px solid #e1e1e1;
}
.Preview__Display__Contents {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Preview__Display__Fold {
  width: 100%;
  padding: 0 16px 4px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.Preview__Display__IndicatorWrap {
  width: 100%;
  padding: 6px 140px 12px 140px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Preview__Contents {
  width: 100%;
  height: auto;
  flex-grow: 1;
  background: #ffffff;
  padding: 16px;
  padding-bottom: 0;
  position: relative;
}
.Preview__Bottom {
  width: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Preview__GreetingWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.Preview__Greeting {
  width: 100%;
  display: flex;
  align-items: start;
}
.Preview__BubbleWrap {
  display: flex;
  flex-direction: column;
}
.Preview__Bubble__bot, .Preview__Bubble__bot__followUp {
  width: fit-content;
  background: #efefef;
  border-radius: 10px;
  padding: 12px 14px;
}
.Preview__Bubble__bot__followUp {
  width: 238px;
}
.Preview__Bubble__userWrap {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.Preview__Bubble__user {
  width: fit-content;
  background: rgba(var(--colorCode-0-r), var(--colorCode-0-g), var(--colorCode-0-b), var(--colorCode-0-a));
  border-radius: 10px;
  padding: 12px 14px;
}
.Preview__Bubble__user p {
  color: #ffffff;
}
.Preview__ExampleContainer {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
.Preview__ExampleContainer::-webkit-scrollbar {
  height: 0px;
}
.Preview__ExampleWrap {
  width: fit-content;
  padding: 0 16px 15px 16px;
  gap: 8px;
  display: flex;
}
.Preview__Example {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(var(--colorCode-2-r), var(--colorCode-2-g), var(--colorCode-2-b), var(--colorCode-2-a));
  border-radius: 99px;
  padding: 6px 14px;
  cursor: pointer;
}
.Preview__Example p {
  color: rgba(var(--colorCode-0-r), var(--colorCode-0-g), var(--colorCode-0-b), var(--colorCode-0-a));
  text-wrap: nowrap;
}
.Preview__Example:hover {
  background: rgba(var(--colorCode-1-r), var(--colorCode-1-g), var(--colorCode-1-b), var(--colorCode-1-a));
}
.Preview__InputContainer {
  width: 100%;
  padding: 8px 16px;
}
.Preview__InputWrap {
  min-width: 100%;
  display: flex;
  align-items: center;
}
.Preview__Input {
  width: 100%;
  height: 44px;
  background: #f6f6f6;
  border-radius: 10px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.CustomSet__Container {
  width: 100%;
  min-width: 0;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  position: relative;
}
.CustomSet__Title {
  display: flex;
  align-items: center;
  gap: 4px;
}
.CustomSet__LogoInput__Img {
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 2px;
  left: 48px;
  cursor: pointer;
}
.CustomSet__TextInputWrap {
  width: 100%;
  padding-right: 36px;
}
.CustomSet__TextInput {
  width: 100%;
  height: 49px;
  background: #f6f6f6;
  border: 2px solid #e1e1e1;
  border-radius: 10px;
  padding: 0 16px;
  font-family: "Pretendard", Arial, sans-serif;
  color: #222222;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}
.CustomSet__CrsTypeScrollWrap {
  width: 100%;
  overflow: hidden;
  height: fit-content;
  padding-right: 36px;
}
.CustomSet__CrsTypeScroll {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  overflow-y: hidden;
}
.CustomSet__CrsTypeScroll::-webkit-scrollbar {
  height: 11px;
}
.CustomSet__CrsTypeScroll::-webkit-scrollbar-thumb {
  border: 2px solid #ffffff;
  background: #e1e1e1;
  border-radius: 6px;
}
.CustomSet__CrsTypeScroll::-webkit-scrollbar-track {
  background: transparent;
}
.CustomSet__CrsTypeItems {
  width: fit-content;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
}
.CustomSet__CrsTypeItem {
  text-align: center;
  flex-shrink: 0;
}
.CustomSet__CrsType, .CustomSet__CrsType__vertical, .CustomSet__CrsType__horizontal, .CustomSet__CrsType__single {
  width: 146px;
  height: 162px;
  background: #f6f6f6;
  border: 2px solid #e1e1e1;
  border-radius: 10px;
  cursor: pointer;
}
.CustomSet__CrsType__NameWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.CustomSet__CrsType__single {
  display: flex;
  align-items: center;
  justify-content: center;
}
.CustomSet__CrsType__horizontal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.CustomSet__CrsType__vertical {
  display: flex;
  align-items: center;
  justify-content: center;
}
.CustomSet__CrsType__activate {
  background: #e0e6f3;
  border: 2px solid #154cca;
}
.CustomSet__RadioWrapVert {
  width: 100%;
  max-width: 436px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.CustomSet__ColorInput, .CustomSet__ColorInput__active {
  width: 90px;
  height: 54px;
  background: #f6f6f6;
  border: 2px solid #e1e1e1;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
}
.CustomSet__ColorInput__Wrap {
  display: flex;
  gap: 12px;
  position: relative;
}
.CustomSet__ColorInput__Block {
  display: flex;
  flex-direction: column;
  gap: 2px;
  position: static;
}
.CustomSet__ColorInput__NameWrap {
  display: flex;
  align-items: center;
  justify-content: start;
}
.CustomSet__ColorInput__Circle {
  width: 26px;
  height: 26px;
  border-radius: 50%;
}
.CustomSet__ColorInput__active {
  border: 2px solid #154cca;
}
.CustomSet__ColorInput__PickerWrap {
  position: absolute;
  top: 80px;
  left: 0;
  z-index: 2;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  border-radius: 0 0 10px 10px;
}
.CustomSet__ColorInput__PickerClose {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.CustomSet__ExInput__Wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.CustomSet__ExInput__ExList {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  align-self: stretch;
}
.CustomSet__ExInput__ExWrap {
  display: flex;
  width: 100%;
  align-items: center;
  flex: 1 0 0;
}
.CustomSet__ExInput__ExBox, .CustomSet__ExInput__ExBox__error, .CustomSet__ExInput__ExBox__default {
  display: flex;
  width: 100%;
  padding: 10px 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 10px;
  background: #f6f6f6;
  gap: 8px;
}
.CustomSet__ExInput__ExBox__default {
  border: 2px solid #e1e1e1;
}
.CustomSet__ExInput__ExBox__default:hover {
  border: 2px solid #154cca;
}
.CustomSet__ExInput__ExBox__error {
  border: 2px solid #ff5555;
}
.CustomSet__ExInput__ExIndex {
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background: #e1e1e1;
  justify-content: center;
  align-items: center;
}
.CustomSet__ExInput__ExTextWrap {
  display: flex;
  align-items: center;
  flex: 1 0 0;
  cursor: default;
}
.CustomSet__ExInput__ExText {
  flex: 1 0 0;
  align-self: stretch;
  overflow: visible;
  white-space: normal;
}
.CustomSet__ExInput__ExRemove {
  display: flex;
  width: 28px;
  height: 28px;
  justify-content: center;
  align-items: center;
  background: #ffffff;
}
.CustomSet__ExInput__padding {
  width: 28px;
}
.CustomSet__ExInput__AddBtnWrap {
  display: flex;
  padding-right: 36px;
  align-items: flex-start;
  align-self: stretch;
}
.CustomSet__ExInput__AddBtn, .CustomSet__ExInput__AddBtn__disabled, .CustomSet__ExInput__AddBtn__default {
  display: flex;
  width: 100%;
  padding: 12px 0;
  border: 2px solid #e1e1e1;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
}
.CustomSet__ExInput__AddBtn__default {
  background: #ffffff;
}
.CustomSet__ExInput__AddBtn__disabled {
  cursor: default;
  background: #e1e1e1;
}
.CustomSet__ExInput__ErrorWrap {
  display: flex;
  width: 300px;
  height: 14px;
  align-items: flex-start;
}
.CustomSet__ExKeywords__KeywordsWrap {
  width: 100%;
  max-width: 436px;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
.CustomSet__ExKeywords__Keyword {
  width: fit-content;
  height: fit-content;
  padding: 14px 16px;
  background: #f6f6f6;
  border-radius: 10px;
  display: flex;
  align-items: center;
}
.CustomSet__ExKeywords__TextInputWrap {
  width: 100%;
  max-width: 436px;
  height: 49px;
  background: #f6f6f6;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.CustomSet__ExKeywords__TextInput {
  width: 100%;
  max-width: 412px;
  height: 100%;
  background: none;
  border: none;
}
.CustomSet__ExKeywords__TextInput:focus-visible {
  outline: none;
}

.Carousel__Container {
  width: 100%;
  height: 100%;
  display: flex;
}
.Carousel__Wrap {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 8px 16px 10px 16px;
  justify-content: center;
  align-items: center;
}
.Carousel__ImageWrap {
  min-width: 132px;
  min-height: 132px;
  border-radius: 8px;
  border: 1px solid #efefef;
  overflow: hidden;
}
.Carousel__ContentsWrap {
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}
.Carousel__SingleButton {
  width: 100%;
  padding: 10px 0px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Carousel__Label {
  width: 36px;
  height: 21px;
  background: #f6f6f6;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Carousel__Horizontal__Wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}
.Carousel__Horizontal__ImageWrap {
  width: 34px;
  height: 34px;
  border-radius: 4px;
  border: 1px solid #efefef;
  overflow: hidden;
  object-fit: cover;
}
.Carousel__Horizontal__ItemWrap {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 16px;
}
.Carousel__Vertical__Wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 16px 4px 16px;
  gap: 8px;
}
.Carousel__Vertical__ImageWrap {
  width: 109px;
  height: 91px;
  border-radius: 8px;
  border: 1px solid #efefef;
  overflow: hidden;
}
.Carousel__Vertical__ContentsWrap {
  display: flex;
  width: 92px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.Carousel__Vertical__NameWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.Carousel__Vertical__ItemWrap {
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex: 1 0 0;
  height: 138px;
  flex-direction: column;
}

.TimerBanner__Container {
  width: 198px;
  height: fit-content;
  display: flex;
  justify-content: center;
}
.TimerBanner__Training__Box {
  width: 198px;
  border-radius: 10px;
  height: 147px;
  background: #ffffff;
  padding: 16px 0;
}
.TimerBanner__Training__Title {
  display: flex;
  align-items: center;
  justify-content: center;
}
.TimerBanner__Training__Gif {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
}
.TimerBanner__Trial__Box {
  width: 198px;
  border-radius: 10px;
  height: 140px;
  background: #e0e6f3;
  padding: 12px 16px;
}
.TimerBanner__Trial__Title {
  display: flex;
  align-items: center;
}
.TimerBanner__Trial__CtaBtn {
  width: 100%;
  height: 41px;
  border: 1px solid #e1e1e1;
  background: #ffffff;
  border-radius: 8px;
}

.TimeProgress__Container {
  width: 100%;
  height: 8px;
  background-color: #ffffff;
  border-radius: 25px;
  overflow: hidden;
}
.TimeProgress__Bar {
  height: 100%;
  background-color: #154cca;
  width: 0%;
  transition: width 0.5s ease-in-out;
  position: relative;
}

.Accodian__Container {
  width: 100%;
  height: fit-content;
  background: #fff;
  border: 1px solid #efefef;
  border-radius: 20px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}
.Accodian__TopWrapper {
  width: 100%;
  padding: 36px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Accodian__TopLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.Accodian__BadgeWrapper {
  width: fit-content;
  display: flex;
  align-items: center;
}
.Accodian__TopBadge, .SquaredAccodianList__TopBadge__secondary, .SquaredAccodianList__TopBadge__primary, .Accodian__TopBadge__secondary, .Accodian__TopBadge__primary {
  width: fit-content;
  height: 33px;
  padding: 8px 12px;
  border-radius: 8px;
}
.Accodian__TopBadge__primary {
  background: #f6f6f6;
}
.Accodian__TopBadge__secondary {
  border: 1px solid #e1e1e1;
}
.Accodian__BottomWrapper {
  width: 100%;
  height: fit-content;
  padding: 36px 32px;
  padding-top: 0;
}

.SquaredAccodianList__Container {
  width: 100%;
  height: fit-content;
}
.SquaredAccodianList__ListContainer {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.SquaredAccodianList__ListWrapper {
  width: 100%;
  padding: 14px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f6f6f6;
  border: 1px solid #efefef;
}
.SquaredAccodianList__ContentWrapper {
  width: 100%;
  height: fit-content;
}
.SquaredAccodianList__Textarea {
  width: 100%;
  height: 122px;
  background: #f6f6f6;
  border: none;
  border-radius: 10px;
  padding: 14px 16px;
}
.SquaredAccodianList__Textarea::placeholder {
  width: 100%;
  height: fit-content;
  font-size: 15px;
  font-weight: 400;
  color: #999999;
  line-height: 19px;
  letter-spacing: -0.025em;
}
.SquaredAccodianList__Title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.SquaredAccodianList__TopBadge {
  width: fit-content;
  height: 33px;
  padding: 8px 12px;
  border-radius: 8px;
}
.SquaredAccodianList__TopBadge__primary {
  background: #f6f6f6;
}
.SquaredAccodianList__TopBadge__secondary {
  border: 1px solid #e1e1e1;
}
.SquaredAccodianList__BottomWrapper {
  width: 100%;
  height: fit-content;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.SquaredAccodianList__ListSwitch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;
}
.SquaredAccodianList__ListSwitch__input {
  opacity: 0;
  width: 0;
  height: 0;
}
.SquaredAccodianList__ListSwitch__input:checked + .SquaredAccodianList__ListSwitch__slider {
  background-color: #28a745;
}
.SquaredAccodianList__ListSwitch__input:checked + .SquaredAccodianList__ListSwitch__slider::before {
  transform: translateX(22px);
}
.SquaredAccodianList__ListSwitch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}
.SquaredAccodianList__ListSwitch__slider::before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}
.SquaredAccodianList__Button__applied, .SquaredAccodianList__Button__applied__disabled {
  width: 72px;
  height: 37px;
  background-color: #E0E5F8;
  border-radius: 10px;
  border: none;
}
.SquaredAccodianList__Button__applied__disabled {
  background-color: #e1e1e1;
  cursor: default;
}
.SquaredAccodianList__Button__cancel {
  width: 72px;
  height: 37px;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.SquaredAccodianList__Button__add {
  width: fit-content;
  height: 37px;
  background-color: #222222;
  border-radius: 10px;
  border: none;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.SquaredAccodianList__AccodianList__Container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.SquaredAccodianList__AccodianList__TitleWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
}

.WidgetSet__Container {
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
}
.WidgetSet__Button, .WidgetSet__Button__disabled, .WidgetSet__Button__active {
  width: fit-content;
  height: fit-content;
  padding: 16px 24px;
  background: #fff;
  border: 2px solid #e1e1e1;
  border-radius: 10px;
}
.WidgetSet__Button__active {
  border: 2px solid #154cca;
}
.WidgetSet__Button__disabled {
  background: #efefef;
  cursor: default;
}
.WidgetSet__PreviewButton {
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 20px;
  border: none;
  border-radius: 10px;
  background: #f2f4fa;
}
.WidgetSet__Divider {
  border-bottom: 2px dashed #e1e1e1;
}
.WidgetSet__FbPos__Container {
  width: 100%;
  height: fit-content;
  display: flex;
}
.WidgetSet__FbPos__ScreenBg, .WidgetSet__FbPos__ScreenBg__mo, .WidgetSet__FbPos__ScreenBg__dt {
  width: 600px;
  height: 540px;
  background: #efefef;
  border: 1px solid #e1e1e1;
  border-radius: 20px;
  overflow: hidden;
}
.WidgetSet__FbPos__ScreenBg__dt {
  padding: 0px 36px 45px 0px;
}
.WidgetSet__FbPos__ScreenBg__mo {
  padding: 60px 180px 0px 180px;
}
.WidgetSet__FbPos__AppScreen, .WidgetSet__FbPos__AppScreen__mo, .WidgetSet__FbPos__AppScreen__dt {
  border-radius: 0 0 30px 0;
  background: #ffffff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  position: relative;
}
.WidgetSet__FbPos__AppScreen__dt {
  width: 564px;
  height: 495px;
}
.WidgetSet__FbPos__AppScreen__mo {
  width: 240px;
  height: 480px;
  border-radius: 20px 20px 0 0;
}
.WidgetSet__FbPos__FbImg, .WidgetSet__FbPos__FbImg__mo, .WidgetSet__FbPos__FbImg__dt {
  position: absolute;
  bottom: calc(var(--fb-pos-bottom) * 0.75px);
  right: calc(var(--fb-pos-right) * 0.75px);
}
.WidgetSet__FbPos__FbImg__dt {
  width: 200px;
  height: 60px;
}
.WidgetSet__FbPos__FbImg__mo {
  width: 156px;
  height: 45px;
}
.WidgetSet__FbPos__FbImg, .WidgetSet__FbPos__FbImg__dt, .WidgetSet__FbPos__FbImg__mo {
  position: absolute;
  bottom: calc(var(--fb-pos-bottom) * 0.75px);
  right: calc(var(--fb-pos-right) * 0.75px);
}
.WidgetSet__FbPos__FbImg__dt {
  width: 200px;
  height: 60px;
}
.WidgetSet__FbPos__FbImg__mo {
  width: 156px;
  height: 45px;
}
.WidgetSet__FbPos__ButtonSettingWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
}
.WidgetSet__FbPos__TabGroup {
  display: flex;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid #e1e1e1;
}
.WidgetSet__FbPos__TabWrap, .WidgetSet__FbPos__TabWrap__unselected, .WidgetSet__FbPos__TabWrap__selected {
  display: flex;
  width: 50%;
  padding-bottom: 2px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.WidgetSet__FbPos__TabWrap__selected {
  border-bottom: 4px solid #154cca;
  cursor: default;
}
.WidgetSet__FbPos__TabWrap__unselected {
  border-bottom: 4px solid transparent;
  cursor: pointer;
}
.WidgetSet__FbPos__Tab, .WidgetSet__FbPos__Tab__unselected, .WidgetSet__FbPos__Tab__selected {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  background: transparent;
}
.WidgetSet__FbPos__Tab__unselected:hover {
  background: #f6f6f6;
}
.WidgetSet__FbPos__ControlWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.CustomRange__Container {
  width: 100%;
  display: flex;
  height: 29px;
  align-items: center;
}
.CustomRange__Label {
  min-width: 26px;
}
.CustomRange__RangeWrap {
  width: 100%;
  position: relative;
}
.CustomRange__Input {
  width: 100%;
  height: 10px;
  background: transparent;
  appearance: none;
  -webkit-appearance: none;
  position: relative;
  z-index: 10;
}
.CustomRange__Input::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  background: linear-gradient(to right, #73adE5 0%, #73adE5 var(--range-progress), #e1e1e1 var(--range-progress), #e1e1e1 100%);
  border-radius: 10px;
}
.CustomRange__Input::-moz-range-track {
  width: 100%;
  height: 10px;
  background: linear-gradient(to right, #73adE5 0%, #73adE5 var(--range-progress), #e1e1e1 var(--range-progress), #e1e1e1 100%);
  border-radius: 10px;
}
.CustomRange__Input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  cursor: pointer;
  margin-top: -7px;
  transition: transform 0.3s ease;
}
.CustomRange__Input::-moz-range-thumb {
  width: 24px;
  height: 24px;
  background: #ffffff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.3s ease;
}
.CustomRange__Input:disabled {
  cursor: not-allowed;
}
.CustomRange__Input:disabled::-webkit-slider-runnable-track {
  background: linear-gradient(to right, #999999 0%, #999999 var(--range-progress), #c0c0c0 var(--range-progress), #c0c0c0 100%);
}
.CustomRange__Input:disabled::-moz-range-track {
  background: linear-gradient(to right, #999999 0%, #999999 var(--range-progress), #c0c0c0 var(--range-progress), #c0c0c0 100%);
}
.CustomRange__Input:disabled::-webkit-slider-thumb {
  background: #efefef;
  cursor: not-allowed;
}
.CustomRange__Input:disabled::-moz-range-thumb {
  background: #efefef;
  cursor: not-allowed;
}
.CustomRange__Tooltip {
  width: fit-content;
  height: 22px;
  position: absolute;
  top: -30px;
  left: calc(var(--position-value) / var(--max) * (var(--input-width) - 24px) + 12px);
  transform: translateX(-50%);
  background: #444444;
  border-radius: 4px;
  padding: 4px 6px;
  z-index: 20;
}
.CustomRange__MarkerContainer {
  position: absolute;
  top: -6px;
  left: 2px;
  width: 100%;
  height: 8px;
  padding: 0px 12px;
  z-index: 1;
}
.CustomRange__Marker {
  width: 0px;
  height: 8px;
  border: 1px solid #e1e1e1;
  position: absolute;
  top: 0px;
  left: var(--deployed-position);
}

.Dropdown__Container {
  position: relative;
  width: 100%;
  height: fit-content;
}
.Dropdown__Input {
  display: flex;
  height: 42px;
  width: 100%;
  padding: 0 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #e1e1e1;
  background: #ffffff;
  cursor: pointer;
}
.Dropdown__ItemList {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 46px;
  z-index: 99;
}
.Dropdown__Item, .Dropdown__Item__selected, .Dropdown__Item__default {
  display: flex;
  height: 42px;
  padding: 0 16px;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
}
.Dropdown__Item__default {
  background: #ffffff;
}
.Dropdown__Item__default:hover {
  background: #f6f6f6;
  border: 1px solid #999999;
}
.Dropdown__Item__selected {
  background: #999999;
}

.TabBar__Header {
  width: 100%;
  display: flex;
  align-items: center;
}
.TabBar__Header__Tab, .TabBar__Header__Tab__selected {
  width: fit-content;
  min-height: 70px;
  border-bottom: 1px solid #e1e1e1;
  padding: 24px;
  cursor: default;
}
.TabBar__Header__Tab__selected {
  border-bottom: 4px solid #154cca;
}
.TabBar__Header__Blank {
  flex: 1;
  min-height: 70px;
  border-bottom: 1px solid #e1e1e1;
}
.TabBar__Header__RightSection {
  display: flex;
  position: relative;
  width: fit-content;
  min-width: 443px;
  height: 70px;
  border-bottom: 1px solid #e1e1e1;
}

.Divider {
  width: 100%;
  height: 1px;
  background: #efefef;
}

.Alerts__InfoMsg__Container {
  width: fit-content;
  height: fit-content;
  padding: 10px 16px;
  background: #efefef;
  border-radius: 8px;
}
.Alerts__ErrorMsg__Container {
  width: fit-content;
  height: fit-content;
  padding: 8px 16px;
  background: #FDF1E7;
  border-radius: 8px;
}
.Alerts__ErrorMsg__Wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Alerts__ErrorMsg__Text {
  color: #C85824;
  white-space: pre-wrap;
}
.Alerts__ErrorText__Wrap {
  width: fit-content;
  height: fit-content;
  display: flex;
}
.Alerts__ErrorText__Text {
  white-space: pre-wrap;
}

.DefaultButton__Disabled {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #e1e1e1;
  border-radius: 10px;
  border: none;
  cursor: default;
}
.DefaultButton__Active {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #222222;
  border-radius: 10px;
  border: none;
}

.DateRangePicker__Container {
  display: flex;
  width: 265px;
  align-items: center;
}
.DateRangePicker__Wrap {
  width: 265px;
  height: 42px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.DateRangePicker__CustomPicker {
  display: flex;
  width: 265px;
  height: 42px;
  padding: 0 16px;
  background: white;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.ConfirmModal__Container {
  width: 450px;
  height: fit-content;
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #e1e1e1;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.25);
  padding: 24px;
}
.ConfirmModal__BtnWrap {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.ConfirmModal__CancelBtn {
  width: fit-content;
  height: fit-content;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  padding: 14px 16px;
}
.ConfirmModal__ConfirmBtn {
  width: fit-content;
  height: fit-content;
  background: #154cca;
  border: none;
  border-radius: 10px;
  padding: 14px 16px;
}

.InfoModal__Container {
  width: 450px;
  height: fit-content;
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #e1e1e1;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.25);
  padding: 24px;
}
.InfoModal__BtnWrap {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.InfoModal__CancelBtn {
  width: fit-content;
  height: fit-content;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  padding: 14px 16px;
}
.InfoModal__ConfirmBtn {
  width: fit-content;
  height: fit-content;
  background: #154cca;
  border: 1px solid #154cca;
  border-radius: 10px;
  padding: 14px 16px;
}

.Onboarding__DimBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Onboarding__Container {
  width: 740px;
  height: fit-content;
  background: #ffffff;
  border-radius: 20px;
}
.Onboarding__Header {
  width: 100%;
  height: 72px;
  padding: 24px;
  border-bottom: 1px solid #e1e1e1;
  display: flex;
  justify-content: space-between;
}
.Onboarding__Title {
  width: 100%;
  height: 100%;
  display: flex;
}
.Onboarding__Footer {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  border-top: 1px solid #e1e1e1;
}
.Onboarding__Btn {
  display: flex;
  width: fit-content;
  height: 48px;
  border: none;
  border-radius: 10px;
  align-items: center;
  background: #154cca;
  padding: 14px 16px;
}

.Prep__Container {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.Prep__Wrap {
  width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
.Prep__TextWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  cursor: default;
}
.Prep__SmallText {
  width: 250px;
  text-align: center;
  white-space: pre-line;
}
.Prep__BetaCta {
  display: flex;
  width: fit-content;
  height: 50px;
  padding: 12px 20px;
  align-items: center;
  border-radius: 10px;
  background: #222222;
  cursor: pointer;
}

.CustomColorPicker__Container {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 292px;
  flex-shrink: 0;
  border-radius: 0 0 10px 10px;
  border: 1px solid #e1e1e1;
  background: #ffffff;
  align-items: center;
}
.CustomColorPicker__Saturation {
  display: flex;
  width: 100%;
  height: 183px;
  position: relative;
}
.CustomColorPicker__Hue {
  width: 252px;
  height: 16px;
  position: relative;
  border-radius: 3px;
  overflow: hidden;
}
.CustomColorPicker__Hex {
  display: flex;
}
.CustomColorPicker__Swatch {
  width: 37px;
  height: 37px;
  background: var(--hex);
  border-radius: 5px 0 0 5px;
}
.CustomColorPicker__CustomSlider {
  width: 16px;
  height: 16px;
  z-index: 10;
  background: hsl(var(--hsl-h), 100%, 50%);
  border-radius: 50%;
  outline: 2px solid #ffffff;
  outline-offset: -1px;
  position: absolute;
  transform: translateX(-50%);
}

.ConsoleFooter__Container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: #ffffff;
}
.ConsoleFooter__Spacer {
  height: 150px;
}

