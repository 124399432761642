@import './guideVar.scss';

.Dashboard {

    &__Container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 946px;
        max-width: 1044px;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        padding: 32px;
    }

}

.Setting {

    &__Wrapper {
        width: 100%;
        display: flex;
        align-items: start;
    }

    &__Date {
        display: flex;
        flex-direction: column;
    }

    &__Channel {
        display: flex;
        flex-direction: column;
        width: 200px;
        align-items: flex-start;
    }
}

.DBLineGraphS {

    &__Container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: $spacing8;
        align-self: stretch;
    }

    &__Wrap {
        width: 100%;
        display: flex;
        align-items: center;
        align-self: stretch;
    }

    &__Body {
        flex: 1 1 0;
        background: $color-white;
        border-radius: 10px;
        border: 1px solid $color-gray-200;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
        padding: $spacing24;
        min-width: 0;
    }
}

.DBGraphL {

    &__Container {
        width: 100%;
        display: flex;
        flex-direction: column;
        background: $color-white;
        border-radius: 10px;
        border: 1px solid $color-gray-200;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
        align-items: center;
        align-self: stretch;
    }

    &__Header {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: $spacing24;
        align-items: flex-start;
        cursor: default;
    }

    &__TextContainer {
        width: 100%;
        display: flex;
        align-items: center;
        cursor: default;
    }

    &__TextWrap {
        display: flex;
        width: 35%;
        min-width: fit-content;
        flex-direction: column;
        align-items: center;
        padding: $spacing32 $spacing16;
    }

    &__Body {
        width: 100%;

        &__line {
            @extend .DBGraphL__Body;
            padding: 0px $spacing40;
        }

        &__bar {
            @extend .DBGraphL__Body;
            padding: 0px $spacing40 50px $spacing40;
        }
    }

    &__Footer {
        display: flex;
        width: 100%;
        padding: $spacing32 $spacing40 $spacing40 $spacing40;
        align-items: center;
        justify-content: flex-start;
        cursor: default;
    }

    &__Legend {
        display: flex;
        width: fit-content;
        align-items: center;
        padding: $spacing12;
        justify-content: center;
        border-radius: 10px;
        border: 1px solid $color-gray-300;
    }

    &__Marker {
        width: 20px;
        height: 20px;
        border-radius: 4px;
        
        &__cart {
            @extend .DBGraphL__Marker;
            background: $color-prim-200;
        }

        &__purchase {
            @extend .DBGraphL__Marker;
            background: $color-prim-700;
        }
    }
}