.Mobile__Container {
  width: 100%;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  position: relative;
}
.Mobile__Background {
  width: 320px;
  height: auto;
}
.Mobile__Fb {
  width: 208px;
  height: 60px;
  position: fixed;
  bottom: calc(var(--posY) * 1px);
  left: calc(50% + 160px - var(--posX) * 1px);
  transform: translateX(-100%);
  z-index: 5;
}

.Desktop__Container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.Desktop__Background {
  width: 100%;
  height: auto;
}
.Desktop__Fb {
  width: 380px;
  height: 80px;
  position: absolute;
  bottom: calc(var(--posY) * 1px);
  right: calc(var(--posX) * 1px);
  z-index: 5;
}/*# sourceMappingURL=deployPreview.module.css.map */