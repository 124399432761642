.Signup__Container {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 56px;
}
.Signup__Wrap {
  width: 472px;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Signup__Header {
  width: 360px;
  min-height: 152px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Signup__InputSection {
  width: 360px;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.Signup__Privacy {
  width: 360px;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  gap: 4px;
}
.Signup__PrivacyCheck {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Signup__InputComp {
  width: 360px;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.Signup__PwInputWrap {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  position: relative;
}
.Signup__Input, .Signup__Input__error {
  width: 100%;
  height: 48px;
  background: #f6f6f6;
  border: none;
  border-radius: 10px;
  padding: 14px 16px;
  font-family: "Pretendard", Arial, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}
.Signup__Input__error:focus-visible {
  outline: 2px solid #ff5555;
}
.Signup__ErrorMsg {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6px;
}
.Signup__GuideMsg {
  display: flex;
  flex-direction: column;
  padding-top: 6px;
}
.Signup__IconMsg {
  display: flex;
  width: 100%;
}
.Signup__Button, .Signup__Button__active, .Signup__Button__disabled {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 10px;
}
.Signup__Button__Wrap {
  width: 360px;
  height: 51px;
  min-height: 51px;
}
.Signup__Button__disabled {
  background: #e1e1e1;
  cursor: default;
}
.Signup__Button__active {
  background: #222222;
}/*# sourceMappingURL=signup.module.css.map */