.Dashboard__Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 946px;
  max-width: 1044px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 32px;
}

.Setting__Wrapper {
  width: 100%;
  display: flex;
  align-items: start;
}
.Setting__Date {
  display: flex;
  flex-direction: column;
}
.Setting__Channel {
  display: flex;
  flex-direction: column;
  width: 200px;
  align-items: flex-start;
}

.DBLineGraphS__Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-self: stretch;
}
.DBLineGraphS__Wrap {
  width: 100%;
  display: flex;
  align-items: center;
  align-self: stretch;
}
.DBLineGraphS__Body {
  flex: 1 1 0;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #efefef;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 24px;
  min-width: 0;
}

.DBGraphL__Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #efefef;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  align-items: center;
  align-self: stretch;
}
.DBGraphL__Header {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: flex-start;
  cursor: default;
}
.DBGraphL__TextContainer {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: default;
}
.DBGraphL__TextWrap {
  display: flex;
  width: 35%;
  min-width: fit-content;
  flex-direction: column;
  align-items: center;
  padding: 32px 16px;
}
.DBGraphL__Body, .DBGraphL__Body__bar, .DBGraphL__Body__line {
  width: 100%;
}
.DBGraphL__Body__line {
  padding: 0px 40px;
}
.DBGraphL__Body__bar {
  padding: 0px 40px 50px 40px;
}
.DBGraphL__Footer {
  display: flex;
  width: 100%;
  padding: 32px 40px 40px 40px;
  align-items: center;
  justify-content: flex-start;
  cursor: default;
}
.DBGraphL__Legend {
  display: flex;
  width: fit-content;
  align-items: center;
  padding: 12px;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #e1e1e1;
}
.DBGraphL__Marker, .DBGraphL__Marker__purchase, .DBGraphL__Marker__cart {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}
.DBGraphL__Marker__cart {
  background: #bbc8e5;
}
.DBGraphL__Marker__purchase {
  background: #0025E4;
}

