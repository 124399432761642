@import './guideVar.scss';

.Signup {

    &__Container {
        width: 100%;
        height: fit-content;
        background: $color-white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding: 56px;
    }

    &__Wrap {
        width: 472px;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__Header {
        width: 360px;
        min-height: 152px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__InputSection {
        width: 360px;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

    &__Privacy {
        width: 360px;
        height: fit-content;
        display: flex;
        align-items: center;
        gap: 4px;
    }

    &__PrivacyCheck {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__InputComp {
        width: 360px;
        height: fit-content;
        min-height: 70px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__PwInputWrap {
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
        position: relative;
    }

    &__Input {
        width: 100%; 
        height: 48px; 
        background: $color-gray-100; 
        border: none; 
        border-radius: 10px; 
        padding: 14px 16px;
        font-family: 'Pretendard', Arial, sans-serif; 
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;

        &__error {
            @extend .Signup__Input;

            &:focus-visible {
                outline: 2px solid $color-warning;
            }
        }
    }

    &__ErrorMsg {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 6px;
    }

    &__GuideMsg {
        display: flex;
        flex-direction: column;
        padding-top: 6px;
    }

    &__IconMsg {
        display: flex;
        width: 100%;
    }

    &__Button {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 10px;

        &__Wrap {
            width: 360px;
            height: 51px;
            min-height: 51px;
        }

        &__disabled {
            @extend .Signup__Button;
            background: $color-gray-300;
            cursor: default;
        }

        &__active {
            @extend .Signup__Button;
            background: $color-gray-800;
        }
    }

}