@import './guideVar.scss';

.PasswordReset {
    &__Container {
        width: 100%;
        height: 100%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__Wrap {
        width: 472px;
        height: 521px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 56px;
    }

    &__TopWrap {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: center;
        padding: 24px 16px;
    }

    &__Toggle {
        &__textBox {
            width: fit-content;
            height: 24px;
            display: flex;
            align-items: center;
        }

        &__imageBox {
            width: fit-content;
            height: 100%;
            display: flex;
            align-items: center;
        }

        &__index {
            width: 24px;
            height: 100%;
            border-radius: 50%;
            background: #e0e6f3;
            display: flex;
            align-items: center;
            justify-content: center;

            &__inactive {
                @extend .PasswordReset__Toggle__index;
                background: #f6f6f6;
            }
        }
    }

    &__Content {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__InputSection {

        &__Container {
            width: 100%;
            height: fit-content;
            display: flex;
            flex-direction: column;
        }

        &__TimerContainer {
            width: 100%;
            height: 60px;
            padding: $spacing12 $spacing16;
            border: 1px solid $color-gray-300;
            border-radius: 10px;
        }

        &__TimerWrap {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__TimerText {
            width: fit-content;
            display: flex;
            align-items: center;
        }

        &__TimerBtn {
            width: 119px;
            height: 35px;
            background: $color-gray-100;
            border: none;
            border-radius: 6px;
        }

    }

    &__BtnWrap {
        height: 51px;
    }
}