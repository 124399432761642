.Modal__Background {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
.Modal__Wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Toast__Container {
  width: 100%;
  height: fit-content;
  position: fixed;
  bottom: 48;
  left: 0;
  z-index: 999;
}
.Toast__Block {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DesignMode__Wrap {
  width: 100%;
  height: fit-content;
  max-width: 1044px;
  min-width: 946px;
  margin-left: auto;
  margin-right: auto;
  padding: 32px;
}
.DesignMode__Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.DesignMode__InterfaceSetting {
  width: 100%;
  position: relative;
}

