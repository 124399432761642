@import '../guideVar.scss';

.Main {
    &__Background {
        width: 100%;
        height: 100vh;
        margin: auto;
        background: $color-white;
        position: fixed;
        overflow-y: scroll;
        overflow-x: hidden;
        // overflow-y: scroll;
    }

    &__Wrap {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        position: absolute;
    }

    &__SideBar__Wrap {
        width: 230px;
        height: 100vh;
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 3;
        overflow-y: auto;
        background: $color-gray-100;
        border-right: 1px solid $color-gray-300;

        &::-webkit-scrollbar {
            width: 11px;
        }
    
        &::-webkit-scrollbar-thumb {
            border: 2px solid $color-gray-100;
            background: $color-gray-500;
            border-radius: 5px; 
        }
    
        &::-webkit-scrollbar-track {
            background: transparent;
        }

        /* Firefox-specific scrollbar styling */
        scrollbar-width: thin;
        scrollbar-color: $color-gray-500 transparent;
    }

    &__SideBar {
        width: 230px;
        //height: 100vh;
        min-height: 669px;
        //background: $color-gray-100;
        //border-right: 1px solid $color-gray-300;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow-y: hidden;
        //position: fixed;
        //top: 0;
        //left: 0;
        //z-index: 3;
        padding: 0 $spacing16 $spacing24 $spacing16;

        &__LogoBox {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            height: fit-content;
            padding: $spacing12 $spacing16;
        }

        &__TopMenuBox {
            width: 100%;
            height: fit-content;
        }

        &__BottomMenuBox {
            width: 100%;
            height: fit-content;
            border-top: 1px solid $color-gray-300;
            padding-top: $spacing16;
        }
    }

    &__List {
        display: flex;
        flex-direction: column;

        &__MenuInput {
            display: flex;
            flex-direction: row;
            font-size: 16px;
            font-weight: 800;
            margin: 4px 0;
            align-items: center;
        
            &[type="radio"] {
                appearance: none;
            }

            &[type=radio]:checked + label {
                background: $color-gray-300;
                border: none;
                border-radius: 10px;

                p {
                    color: $color-gray-800;
                }

                img {
                    filter: brightness(0);
                }
            }
        }

        &__MenuLabel {
            width: 100%;
            display: flex;
            align-items: center;
            padding: $spacing12 $spacing16;
            border: none;
            border-radius: 10px;

            &__selected {
                @extend .Main__List__MenuLabel;
                background: $color-gray-300;
                p {
                    color: $color-gray-800;
                }
            }
        }

        &__SubLabel {
            &__Wrap {
                width: 100%;
                height: 40px;
                padding: $spacing16 $spacing8 $spacing16 $spacing32;
            }

            &__Content {
                width: 100%;
                padding: $spacing16 $spacing8 $spacing16 $spacing56;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                border: none;
                border-radius: 10px;

                &__selected {
                    @extend .Main__List__SubLabel__Content;
                    background: $color-prim-100;
                    p {
                        color: $color-prim-800;
                    }
                }
            }

        }
    }

    &__Container {
        width: 100%;
        position: relative;
    }

    &__OutletWrap {
        width: calc(100% - 230px);
        height: calc(100% - 50px);
        overflow-y: scroll;
        position: relative;
        top: 0;
        left: 230px;
    }

    &__Outlet {
        width: calc(100% - 272px);
        overflow-y: scroll;
    }
}

.TopNav {

    &__Container {
        width: calc(100% - 230px);
        height: 50px;
        position: relative;
        top: 0;
        left: 230px;
        min-height: 40px;
        min-width: 1010px;
        background-color: $color-white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $color-gray-200;
        padding: 15px 32px;
    }

    &__Wrap {
        width: auto;
        height: fit-content;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

}