
.apexcharts-bar-area:hover {
    fill: #154CCA !important;
}

.apexcharts-tooltip {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15) !important;
    border-radius: 8px !important;
    border: 1px solid #EFEFEF !important;
}
