.PasswordReset__Container {
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.PasswordReset__Wrap {
  width: 472px;
  height: 521px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 56px;
}
.PasswordReset__TopWrap {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  padding: 24px 16px;
}
.PasswordReset__Toggle__textBox {
  width: -moz-fit-content;
  width: fit-content;
  height: 24px;
  display: flex;
  align-items: center;
}
.PasswordReset__Toggle__imageBox {
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
  display: flex;
  align-items: center;
}
.PasswordReset__Toggle__index, .PasswordReset__Toggle__index__inactive {
  width: 24px;
  height: 100%;
  border-radius: 50%;
  background: #e0e6f3;
  display: flex;
  align-items: center;
  justify-content: center;
}
.PasswordReset__Toggle__index__inactive {
  background: #f6f6f6;
}
.PasswordReset__Content {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.PasswordReset__InputSection__Container {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
}
.PasswordReset__InputSection__TimerContainer {
  width: 100%;
  height: 60px;
  padding: 12px 16px;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
}
.PasswordReset__InputSection__TimerWrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.PasswordReset__InputSection__TimerText {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
}
.PasswordReset__InputSection__TimerBtn {
  width: 119px;
  height: 35px;
  background: #f6f6f6;
  border: none;
  border-radius: 6px;
}
.PasswordReset__BtnWrap {
  height: 51px;
}/*# sourceMappingURL=passwordReset.module.css.map */