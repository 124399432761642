@import './guideVar.scss';

.Toast {
    &__Container {
        width: fit-content;
        height: fit-content;
    }

    &__Background {
        width: 100%;
        height: 100%;
        background: $color-gray-800;
        padding: $spacing16;
        border-radius: 10px;
        display: flex;
        align-items: center;
    }

    &__Button {
        width: 68px;
        height: 34px;
        background: $color-white;
        border: none;
        border-radius: 10px;
    }
}

.CustomTooltip {
    &__Wrap {
        position: relative;
    }

    &__Icon {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__TipBox {
        width: max-content;
        height: auto;
        max-width: 208px;
        padding: 14px;
        background: $color-gray-800;
        z-index: 9999;
        border-radius: 10px;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.25);
        position: fixed;
        top: calc(var(--rect-bottom) * 1px);
        left: calc(var(--rect-left) * 1px + var(--rect-width) * 1px/2);
        transform: translateX(calc(-50%));
    }

    &__TipText {
        text-align: start;
        white-space: pre-wrap;
    }

    &__RefreshWrap {
        position: relative;
        width: 28px;
        height: 28px;
    }

    &__RefreshTipBox {
        width: max-content;
        height: auto;
        max-width: 208px;
        padding: $spacing8 10px;
        background: $color-gray-800;
        position: absolute;
        bottom: 36px;
        transform: translateX(calc(-50% + 12px));
        z-index: 9999;
        border-radius: 10px;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.25);
    }

}

.Button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $spacing12 $spacing16;
    cursor: pointer;
    
    &__primary {
        @extend .Button;
        width: 100%;
        height: 100%;
        background: $color-prim-800;
        border: none;
        border-radius: 10px;
    }

    &__secondary {
        @extend .Button;
        width: 100%;
        height: 100%;
        background: $color-gray-400;
        border: none;
        border-radius: 10px;
    }

    &__cancel {
        @extend .Button;
        width: 100%;
        height: 100%;
        background: $color-white;
        border: 1px solid $color-prim-800;
        border-radius: 10px;
    }
}

.Flex {
    width: 100%;
    height: fit-content;
    display: flex;

    &__HCenter {
        @extend .Flex;
        align-items: center;
        justify-content: center;
    }

    &__HRight {
        @extend .Flex__HCenter;
        justify-content: flex-end;
    }

    &__HLeft {
        @extend .Flex__HCenter;
        justify-content: flex-start;
    }

    &__VCenter {
        @extend .Flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.Break {
    &__Hr {
        --width: 100%;
        --thickness: 1px;
        --color: #e1e1e1;
        width: var(--width);
        height: 1px;
        border: var(--thickness) solid var(--color);
    }
}

.Preview {
    &__Container {
        width: 455px;
        min-width: 375px;
        height: 100%;
        background: $color-gray-200;
        display: flex;
        align-items: end;
        justify-content: center;
        border-radius: 20px;
    }

    &__Wrap {
        width: 375px;
        height: 679px;
        background: $color-white;
        border-radius: 30px 30px 0 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
    }

    &__Header {
        width: 100%;
        height: 44px;
        background: $color-white;
    }

    &__Display {
        width: 100%;
        display: flex;
        flex-direction: column;
        background: $color-white;
        position: relative;
        z-index: 5;

        &__Divider {
            width: 100%;
            height: 6px;
            background: $color-gray-200;
            border-bottom: 1px solid $color-gray-300;
        }

        &__Contents {
            width: 100%;
            height: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__Fold {
            width: 100%;
            padding: 0 $spacing16 $spacing4 $spacing16;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        &__IndicatorWrap {
            width: 100%;
            padding: 6px 140px 12px 140px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__Contents {
        width: 100%;
        height: auto;
        flex-grow: 1;
        background: $color-white;
        padding: $spacing16;
        padding-bottom: 0;
        position: relative;
    }

    &__Bottom {
        width: 100%;
        background: $color-white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__GreetingWrap {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__Greeting {
        width: 100%;
        display: flex;
        align-items: start;
    }

    &__BubbleWrap {
        display: flex;
        flex-direction: column;
    }

    &__Bubble {
        &__bot {
            width: fit-content;
            background: $color-gray-200;
            border-radius: 10px;
            padding: $spacing12 $spacing14;

            &__followUp {
                @extend .Preview__Bubble__bot;
                width: 238px;
            }
        }

        &__userWrap {
            display: flex;
            width: 100%;
            justify-content: flex-end;
        }

        &__user {
            width: fit-content;
            background: rgba(var(--colorCode-0-r), var(--colorCode-0-g), var(--colorCode-0-b), var(--colorCode-0-a));
            border-radius: 10px;
            padding: $spacing12 $spacing14;

            p {
                color: $color-white;
            }

        }
    }

    &__ExampleContainer {
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;

        &::-webkit-scrollbar {
            height: 0px;
        }
    }

    &__ExampleWrap {
        width: fit-content;
        padding: 0 $spacing16 15px $spacing16;
        gap: $spacing8;
        display: flex;
    }

    &__Example {
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(var(--colorCode-2-r), var(--colorCode-2-g), var(--colorCode-2-b), var(--colorCode-2-a));
        border-radius: 99px;
        padding: 6px 14px;
        cursor: pointer;

        p {
            color: rgba(var(--colorCode-0-r), var(--colorCode-0-g), var(--colorCode-0-b), var(--colorCode-0-a));
            text-wrap: nowrap;
        }

        &:hover {
            background: rgba(var(--colorCode-1-r), var(--colorCode-1-g), var(--colorCode-1-b), var(--colorCode-1-a));
        }
    }

    &__InputContainer {
        width: 100%;
        padding: $spacing8 $spacing16;
    }

    &__InputWrap {
        min-width: 100%;
        display: flex;
        align-items: center;
    }

    &__Input {
        width: 100%;
        height: 44px;
        background: $color-gray-100;
        border-radius: 10px;
        padding: $spacing8 $spacing16;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.CustomSet {
    &__Container {
        width: 100%;
        min-width: 0;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: flex-start;
        position: relative;
    }

    &__Title {
        display: flex;
        align-items: center;
        gap: $spacing4;
    }

    &__LogoInput {
        &__Img {
            width: 32px;
            height: 32px;
            position: absolute;
            bottom: 2px;
            left: 48px;
            cursor: pointer;
        }
    }

    &__TextInputWrap {
        width: 100%;
        padding-right: 36px;
    }

    &__TextInput {
        width: 100%;
        height: 49px;
        background: $color-gray-100;
        border: 2px solid $color-gray-300;
        border-radius: 10px;
        padding: 0 $spacing16;
        font-family: 'Pretendard', Arial, sans-serif;
        color: $color-gray-800;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
    }

    &__CrsTypeScrollWrap {
        width: 100%;
        overflow: hidden;
        height: fit-content;
        padding-right: 36px;
    }

    &__CrsTypeScroll {
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow-x: auto;
        overflow-y: hidden;

        &::-webkit-scrollbar {
            height: 11px;
        }

        &::-webkit-scrollbar-thumb {
            border: 2px solid $color-white;
            background: $color-gray-300;
            border-radius: 6px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }
    }

    &__CrsTypeItems {
        width: fit-content;
        display: flex;
        flex-direction: row;
        flex-shrink: 0;
    }

    &__CrsTypeItem {
        text-align: center;
        flex-shrink: 0;
    }

    &__CrsType {
        width: 146px;
        height: 162px;
        background: $color-gray-100;
        border: 2px solid $color-gray-300;
        border-radius: 10px;
        cursor: pointer;

        &__NameWrap {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: $spacing4;
        }

        &__single {
            @extend .CustomSet__CrsType;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__horizontal {
            @extend .CustomSet__CrsType;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__vertical {
            @extend .CustomSet__CrsType;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__activate {
            background: $color-prim-100;
            border: 2px solid $color-prim-800;
        }
    }

    &__RadioWrapVert {
        width: 100%;
        max-width: 436px;
        display: flex;
        flex-direction: column;
        gap: $spacing16;
    }

    &__ColorInput {
        width: 90px;
        height: 54px;
        background: $color-gray-100;
        border: 2px solid $color-gray-300;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: $spacing8;
        cursor: pointer;

        &__Wrap {
            display: flex;
            gap: $spacing12;
            position: relative;
        }

        &__Block {
            display: flex;
            flex-direction: column;
            gap: $spacing2;
            position: static;
        }

        &__NameWrap {
            display: flex;
            align-items: center;
            justify-content: start;
        }

        &__Circle {
            width: 26px;
            height: 26px;
            border-radius: 50%;
        }

        &__active {
            @extend .CustomSet__ColorInput;
            border: 2px solid $color-prim-800;
        }

        &__PickerWrap {
            position: absolute;
            top: 80px;
            left: 0;
            z-index: 2;
            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
            border-radius: 0 0 10px 10px;
        }

        &__PickerClose {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
        }
    }

    &__ExInput {
        
        &__Wrap {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            align-self: stretch;
        }

        &__ExList {
            display: flex;
            flex-direction: column;
            gap: $spacing8;
            align-items: flex-start;
            align-self: stretch;
        }

        &__ExWrap {
            display: flex;
            width: 100%;
            align-items: center;
            flex: 1 0 0;
        }

        &__ExBox {
            display: flex;
            width: 100%;
            padding: 10px $spacing16;
            align-items: center;
            align-self: stretch;
            border-radius: 10px;
            background: $color-gray-100;
            gap: $spacing8;

            &__default {
                @extend .CustomSet__ExInput__ExBox;
                border: 2px solid $color-gray-300;

                &:hover {
                    border: 2px solid $color-prim-800;
                }
            }

            &__error {
                @extend .CustomSet__ExInput__ExBox;
                border: 2px solid $color-warning;
            }
        }

        &__ExIndex {
            display: flex;
            width: 20px;
            height: 20px;
            border-radius: 4px;
            background: $color-gray-300;
            justify-content: center;
            align-items: center;
        }

        &__ExTextWrap {
            display: flex;
            align-items: center;
            flex: 1 0 0;
            cursor: default;
        }

        &__ExText {
            flex: 1 0 0;
            align-self: stretch;
            overflow: visible;
            white-space: normal;
        }

        &__ExRemove {
            display: flex;
            width: 28px;
            height: 28px;
            justify-content: center;
            align-items: center;
            background: $color-white;
        }

        &__padding {
            width: 28px;
        }

        &__AddBtnWrap {
            display: flex;
            padding-right: 36px;
            align-items: flex-start;
            align-self: stretch;
        }

        &__AddBtn {
            display: flex;
            width: 100%;
            padding: $spacing12 0;
            border: 2px solid $color-gray-300;
            border-radius: 10px;
            justify-content: center;
            align-items: center;
            align-self: stretch;
            cursor: pointer;

            &__default {
                @extend .CustomSet__ExInput__AddBtn;
                background: $color-white;
            }

            &__disabled {
                @extend .CustomSet__ExInput__AddBtn;
                cursor: default;
                background: $color-gray-300;
            }
        }

        &__ErrorWrap {
            display: flex;
            width: 300px;
            height: 14px;
            align-items: flex-start;
        }
    }

    &__ExKeywords {
        &__KeywordsWrap {
            width: 100%;
            max-width: 436px;
            display: flex;
            gap: $spacing8;
            flex-wrap: wrap;
        }

        &__Keyword {
            width: fit-content;
            height: fit-content;
            padding: 14px $spacing16;
            background: $color-gray-100;
            border-radius: 10px;
            display: flex;
            align-items: center;
        }

        &__TextInputWrap {
            width: 100%;
            max-width: 436px;
            height: 49px;
            background: $color-gray-100;
            border: 1px solid $color-gray-300;
            border-radius: 10px;
            padding: 0 $spacing16;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    
        &__TextInput {
            width: 100%;
            max-width: 412px;
            height: 100%;
            background: none;
            border: none;
    
            &:focus-visible {
                outline: none;
            }
        }
    }
}

.Carousel {
    &__Container {
        width: 100%;
        height: 100%;
        display: flex;
        // padding-left: $spacing40;
        // overflow-x: scroll;
        // gap: $spacing8;
    }

    &__Wrap {
        width: 100%;
        height: 100%;
        display: flex;
        padding: $spacing8 $spacing16 10px $spacing16;
        justify-content: center;
        align-items: center;
    }

    &__ImageWrap {
        min-width: 132px;
        min-height: 132px;
        border-radius: 8px;
        border: 1px solid $color-gray-200;
        overflow: hidden;
    }

    &__ContentsWrap {
        height: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
    }

    &__SingleButton {
        width: 100%;
        padding: 10px 0px;
        border: 1px solid $color-gray-300;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__Label {
        width: 36px;
        height: 21px;
        background: $color-gray-100;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__Horizontal {
        &__Wrap {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-around;
        }

        &__ImageWrap {
            width: 34px;
            height: 34px;
            border-radius: 4px;
            border: 1px solid $color-gray-200;
            overflow: hidden;
            object-fit: cover;
        }

        &__ItemWrap {
            width: 100%;
            height: fit-content;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: $spacing8 $spacing16;
        }
    }

    &__Vertical {
        &__Wrap {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 8px $spacing16 $spacing4 $spacing16;
            gap: $spacing8;
        }

        &__ImageWrap {
            width: 109px;
            height: 91px;
            border-radius: 8px;
            border: 1px solid $color-gray-200;
            overflow: hidden;
        }

        &__ContentsWrap {
            display: flex;
            width: 92px;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }

        &__NameWrap {
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        &__ItemWrap {
            height: fit-content;
            display: flex;
            align-items: flex-start;
            flex: 1 0 0;
            height: 138px;
            flex-direction: column;
        }
    }
}

.TimerBanner {
    &__Container {
        width: 198px;
        height: fit-content;
        display: flex;
        justify-content: center;
    }

    &__Training {

        &__Box {
            width: 198px;
            border-radius: 10px;
            height: 147px;
            background: $color-white;
            padding: $spacing16 0;
        }

        &__Title {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__Gif {
            width: 100%;
            height: fit-content;
            display: flex;
            justify-content: center;
        }
    }

    &__Trial {

        &__Box {
            width: 198px;
            border-radius: 10px;
            height: 140px;
            background: $color-prim-100;
            padding: $spacing12 $spacing16;
        }

        &__Title {
            display: flex;
            align-items: center;
        }

        &__CtaBtn {
            width: 100%;
            height: 41px;
            border: 1px solid $color-gray-300;
            background: $color-white;
            border-radius: 8px;
        }
    }
    
}


.TimeProgress {
    &__Container {
        width: 100%;
        height: 8px;
        background-color: $color-white;
        border-radius: 25px;
        overflow: hidden;
    }

    &__Bar {
        height: 100%;
        background-color: $color-prim-800;
        width: 0%;
        transition: width 0.5s ease-in-out;
        position: relative;
    }
}

.Accodian {
    &__Container {
        width: 100%; 
        height: fit-content;
        background: #fff;
        border: 1px solid $color-gray-200;
        border-radius: 20px;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    }

    &__TopWrapper {
        width: 100%;
        padding: 36px 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__TopLeft {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    &__BadgeWrapper {
        width: fit-content;
        display: flex;
        align-items: center;
    }

    &__TopBadge {
        width: fit-content;
        height: 33px;
        padding: 8px 12px;
        border-radius: 8px;

        &__primary {
            @extend .Accodian__TopBadge;
            background: $color-gray-100;
        }

        &__secondary {
            @extend .Accodian__TopBadge;
            border: 1px solid $color-gray-300;
        }
    }

    &__BottomWrapper {
        width: 100%;
        height: fit-content;
        padding: 36px 32px;
        padding-top: 0;
    }
}

.SquaredAccodianList {
    &__Container {
        width: 100%; 
        height: fit-content;
    }
    
    &__ListContainer {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
    
    &__ListWrapper {
        width: 100%;
        padding: 14px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: $color-gray-100;
        border: 1px solid $color-gray-200;
    }

    &__ContentWrapper {
        width: 100%;
        height: fit-content;
        // display: flex;
        // flex-direction: column;
        // align-items: flex-start;
        // justify-content: center;
    }

    &__Textarea {
        width: 100%;
        height: 122px;
        background: $color-gray-100;
        border: none;
        border-radius: 10px;
        padding: 14px 16px;

        &::placeholder {
            width: 100%;
            height: fit-content;
            font-size: 15px;
            font-weight: 400;
            color: $color-gray-500;
            line-height: 19px;
            letter-spacing: -0.025em;
        }
    }

    &__Title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    &__TopBadge {
        width: fit-content;
        height: 33px;
        padding: 8px 12px;
        border-radius: 8px;

        &__primary {
            @extend .Accodian__TopBadge;
            background: $color-gray-100;
        }

        &__secondary {
            @extend .Accodian__TopBadge;
            border: 1px solid $color-gray-300;
        }
    }

    &__BottomWrapper {
        width: 100%;
        height: fit-content;
        padding: 20px 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__ListSwitch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 28px;

        &__input {
            opacity: 0;
            width: 0;
            height: 0;

            &:checked + .SquaredAccodianList__ListSwitch__slider {
                background-color: #28a745;
            }

            &:checked + .SquaredAccodianList__ListSwitch__slider::before {
                transform: translateX(22px);
            }
        }

        &__slider {
            position: absolute;
            cursor: pointer;
            top: 0; left: 0;
            right: 0; bottom: 0;
            background-color: #ccc;
            transition: 0.4s;
            border-radius: 34px;

            &::before {
                position: absolute;
                content: "";
                height: 22px;
                width: 22px;
                left: 3px;
                bottom: 3px;
                background-color: white;
                transition: 0.4s;
                border-radius: 50%;
            }
        }
    }

    &__Button {
        &__applied {
            width: 72px;
            height: 37px;
            background-color: $color-prim-150;
            border-radius: 10px;
            border: none;

            &__disabled {
                @extend .SquaredAccodianList__Button__applied;
                background-color: $color-gray-300;
                cursor: default;
            }
        }

        &__cancel {
            width: 72px;
            height: 37px;
            background-color: $color-white;
            border-radius: 10px;
            border: 1px solid $color-gray-300;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
        }

        &__add {
            width: fit-content;
            height: 37px;
            background-color: $color-gray-800;
            border-radius: 10px;
            border: none;
            padding: 10px 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
        }
    }

    &__AccodianList {
        &__Container {
            width: 100%;
            height: fit-content;
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        &__TitleWrap {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            cursor: pointer;
        }
    }
}

.WidgetSet {
    &__Container {
        width: fit-content;
        height: fit-content;
        display: flex;
        align-items: center;
    }

    &__Button {
        width: fit-content;
        height: fit-content;
        padding: 16px 24px;
        background: #fff;
        border: 2px solid #e1e1e1;
        border-radius: 10px;

        &__active {
            @extend .WidgetSet__Button;
            border: 2px solid #154cca;
        }

        &__disabled {
            @extend .WidgetSet__Button;
            background: #efefef;
            cursor: default;
        }
    }

    &__PreviewButton {
        width: fit-content;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 20px;
        border: none;
        border-radius: 10px;
        background: $color-prim-50;
    }

    &__Divider {
        border-bottom: 2px dashed $color-gray-300;
    }

    &__FbPos {

        &__Container {
            width: 100%;
            height: fit-content;
            display: flex;
        }

        &__ScreenBg {
            width: 600px;
            height: 540px;
            background: $color-gray-200;
            border: 1px solid $color-gray-300;
            border-radius: 20px;
            overflow: hidden;

            &__dt {
                @extend .WidgetSet__FbPos__ScreenBg;
                padding: 0px 36px 45px 0px;
            }

            &__mo {
                @extend .WidgetSet__FbPos__ScreenBg;
                padding: 60px 180px 0px 180px;
            }
        }

        &__AppScreen {
            border-radius: 0 0 30px 0;
            background: $color-white;
            box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
            position: relative;

            &__dt {
                @extend .WidgetSet__FbPos__AppScreen;
                width: 564px;
                height: 495px;
            }

            &__mo {
                @extend .WidgetSet__FbPos__AppScreen;
                width: 240px;
                height: 480px;
                border-radius: 20px 20px 0 0;
            }
        }

        &__FbImg {
            position: absolute;
            bottom: calc(var(--fb-pos-bottom) * 0.75px);
            right: calc(var(--fb-pos-right) * 0.75px);

            &__dt {
                @extend .WidgetSet__FbPos__FbImg;
                width: 200px;
                height: 60px;
            }

            &__mo {
                @extend .WidgetSet__FbPos__FbImg;
                width: 156px;
                height: 45px;
            }
        }

        &__FbImg {
            position: absolute;
            bottom: calc(var(--fb-pos-bottom) * 0.75px);
            right: calc(var(--fb-pos-right) * 0.75px);

            &__dt {
                @extend .WidgetSet__FbPos__FbImg;
                width: 200px;
                height: 60px;
            }

            &__mo {
                @extend .WidgetSet__FbPos__FbImg;
                width: 156px;
                height: 45px;
            }
        }

        &__ButtonSettingWrap {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 1 0 0;
        }

        &__TabGroup {
            display: flex;
            align-items: center;
            align-self: stretch;
            border-bottom: 1px solid $color-gray-300;
        }

        &__TabWrap {
            display: flex;
            width: 50%;
            padding-bottom: 2px;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &__selected {
                @extend .WidgetSet__FbPos__TabWrap;
                border-bottom: 4px solid $color-prim-800;
                cursor: default;
            }

            &__unselected {
                @extend .WidgetSet__FbPos__TabWrap;
                border-bottom: 4px solid transparent;
                cursor: pointer;
            }
        }

        &__Tab {
            display: flex;
            padding: $spacing8 $spacing12;
            justify-content: center;
            align-items: center;
            align-self: stretch;
            border-radius: 6px;
            background: transparent;
            
            &__selected {
                @extend .WidgetSet__FbPos__Tab;
            }

            &__unselected {
                @extend .WidgetSet__FbPos__Tab;

                &:hover {
                    background: $color-gray-100;
                }
            }
            
        }

        &__ControlWrap {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
        }
    }
}

.CustomRange {

    &__Container {
        width: 100%;
        display: flex;
        height: 29px;
        align-items: center;
    }

    &__Label {
        min-width: 26px;
    }

    &__RangeWrap {
        width: 100%;
        position: relative;
    }

    &__Input {
        width: 100%;
        height: 10px;
        background: transparent;
        appearance: none;
        -webkit-appearance: none;
        position: relative;
        z-index: 10;
        
        // Track styles
        &::-webkit-slider-runnable-track {
            width: 100%;
            height: 10px;
            background: linear-gradient(
                to right,
                #73adE5 0%,
                #73adE5 var(--range-progress),
                $color-gray-300 var(--range-progress),
                $color-gray-300 100%
            );
            border-radius: 10px;
        }
        
        &::-moz-range-track {
            width: 100%;
            height: 10px;
            background: linear-gradient(
                to right,
                #73adE5 0%,
                #73adE5 var(--range-progress),
                $color-gray-300 var(--range-progress),
                $color-gray-300 100%
            );
            border-radius: 10px;
        }
        
        // Thumb styles
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 24px;
            height: 24px;
            background: $color-white;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
            border-radius: 50%;
            cursor: pointer;
            margin-top: -7px;
            transition: transform 0.3s ease;
        }
        
        &::-moz-range-thumb {
            width: 24px;
            height: 24px;
            background: $color-white;
            border: none;
            border-radius: 50%;
            cursor: pointer;
            transition: transform 0.3s ease;
        }

        &:disabled {
            cursor: not-allowed;

            &::-webkit-slider-runnable-track {
                background: linear-gradient(
                    to right,
                    $color-gray-500 0%,
                    $color-gray-500 var(--range-progress),
                    $color-gray-400 var(--range-progress),
                    $color-gray-400 100%
                );
            }

            &::-moz-range-track {
                background: linear-gradient(
                    to right,
                    $color-gray-500 0%,
                    $color-gray-500 var(--range-progress),
                    $color-gray-400 var(--range-progress),
                    $color-gray-400 100%
                );
            }

            &::-webkit-slider-thumb {
                background: $color-gray-200;
                cursor: not-allowed;
            }

            &::-moz-range-thumb {
                background: $color-gray-200;
                cursor: not-allowed;
            }
        }
    }



    &__Tooltip {
        width: fit-content;
        height: 22px;
        position: absolute;
        top: -30px;
        left: calc((var(--position-value) / var(--max)) * (var(--input-width) - 24px) + 12px);
        transform: translateX(-50%);
        background: $color-gray-700;
        border-radius: 4px;
        padding: 4px 6px;
        z-index: 20;
    }
    
    &__MarkerContainer {
        position: absolute;
        top: -6px;
        left: 2px;
        width: 100%;
        height: 8px;
        padding: 0px $spacing12;
        z-index: 1;
    }

    &__Marker {
        width: 0px;
        height: 8px;
        border: 1px solid #e1e1e1;
        position: absolute;
        top: 0px;
        left: var(--deployed-position);
    }
}

.Dropdown {

    &__Container {
        position: relative;
        width: 100%;
        height: fit-content;
    }

    &__Input {
        display: flex;
        height: 42px;
        width: 100%;
        padding: 0 $spacing16;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        border: 1px solid $color-gray-300;
        background: $color-white;
        cursor: pointer;
    }

    &__ItemList {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 8px;
        background: $color-white;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
        position: absolute;
        top: 46px;
        z-index: 99;
    }

    &__Item {
        display: flex;
        height: 42px;
        padding: 0 $spacing16;
        align-items: center;
        border-radius: 8px;
        cursor: pointer;

        &__default {
            @extend .Dropdown__Item;
            background: $color-white;

            &:hover {
                background: $color-gray-100;
                border: 1px solid $color-gray-500;
            }
        }

        &__selected {
            @extend .Dropdown__Item;
            background: $color-gray-500;
        }

    }
}

.TabBar {
    &__Header {
        width: 100%;
        display: flex;
        align-items: center;

        &__Tab {
            width: fit-content;
            min-height: 70px;
            border-bottom: 1px solid $color-gray-300;
            padding: $spacing24;
            cursor: default;
            
            &__selected {
                @extend .TabBar__Header__Tab;
                border-bottom: 4px solid $color-prim-800;
            }
        }

        &__Blank {
            flex: 1;
            min-height: 70px;
            border-bottom: 1px solid $color-gray-300;
        }

        &__RightSection {
            display: flex;
            position: relative;
            width: fit-content;
            min-width: 443px;
            height: 70px;
            border-bottom: 1px solid $color-gray-300;
        }
    }
}

.Divider {
    width: 100%;
    height: 1px;
    background: $color-gray-200;
}

.Alerts {

    &__InfoMsg {
        &__Container {
            width: fit-content;
            height: fit-content;
            padding: 10px $spacing16;
            background: $color-gray-200;
            border-radius: 8px;
        } 
    }

    &__ErrorMsg {
        &__Container {
            width: fit-content;
            height: fit-content;
            padding: $spacing8 $spacing16;
            background: #FDF1E7;
            border-radius: 8px;
        }

        &__Wrap {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__Text {
            color: #C85824;
            white-space: pre-wrap;
        }
    }

    &__ErrorText {
        &__Wrap {
            width: fit-content;
            height: fit-content;
            display: flex;
        }

        &__Text {
            white-space: pre-wrap;
        }
    }
}

.DefaultButton {
    &__Disabled {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: $color-gray-300;
        border-radius: 10px;
        border: none;
        cursor: default;
    }
    
    &__Active {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: $color-gray-800;
        border-radius: 10px;
        border: none;
    }
}

.DateRangePicker {
    &__Container {
        display: flex;
        width: 265px;
        align-items: center;
    }

    &__Wrap {
        width: 265px;
        height: 42px;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    &__CustomPicker {
        display: flex;
        width: 265px;
        height: 42px;
        padding: 0 $spacing16;
        background: white;
        border: 1px solid $color-gray-300;
        border-radius: 8px;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }
}

.ConfirmModal {

    &__Container {
        width: 450px;
        height: fit-content;
        background: $color-white;
        border-radius: 20px;
        border: 1px solid $color-gray-300;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.25);
        padding: 24px;
    }

    &__BtnWrap {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    &__CancelBtn {
        width: fit-content;
        height: fit-content;
        background: $color-white;
        border: 1px solid $color-gray-300;
        border-radius: 10px;
        padding: 14px 16px;
    }

    &__ConfirmBtn {
        width: fit-content;
        height: fit-content;
        background: $color-prim-800;
        border: none;
        border-radius: 10px;
        padding: 14px 16px;
    }
    
}

.InfoModal {

    &__Container {
        width: 450px;
        height: fit-content;
        background: $color-white;
        border-radius: 20px;
        border: 1px solid $color-gray-300;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.25);
        padding: 24px;
    }

    &__BtnWrap {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    &__CancelBtn {
        width: fit-content;
        height: fit-content;
        background: $color-white;
        border: 1px solid $color-gray-300;
        border-radius: 10px;
        padding: 14px 16px;
    }

    &__ConfirmBtn {
        width: fit-content;
        height: fit-content;
        background: $color-prim-800;
        border: 1px solid $color-prim-800;
        border-radius: 10px;
        padding: 14px 16px;
    }
    
}

.Onboarding {
    &__DimBg {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__Container {
        width: 740px;
        height: fit-content;
        background: $color-white;
        border-radius: 20px;
    }

    &__Header {
        width: 100%;
        height: 72px;
        padding: 24px;
        border-bottom: 1px solid $color-gray-300;
        display: flex;
        justify-content: space-between;
    }

    &__Title {
        width: 100%;
        height: 100%;
        display: flex;
    }

    &__Footer {
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: flex-end;
        padding: 16px;
        border-top: 1px solid $color-gray-300;
    }

    &__Btn {
        display: flex;
        width: fit-content;
        height: 48px;
        border: none;
        border-radius: 10px;
        align-items: center;
        background: $color-prim-800;
        padding: 14px 16px;
    }
    
}

.Prep {

    &__Container {
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }

    &__Wrap {
        width: 250px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }

    &__TextWrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        cursor: default;
    }

    &__SmallText {
        width: 250px;
        text-align: center;
        white-space: pre-line;
    }

    &__BetaCta {
        display: flex;
        width: fit-content;
        height: 50px;
        padding: 12px 20px;
        align-items: center;
        border-radius: 10px;
        background: $color-gray-800;
        cursor: pointer;
    }
}

.CustomColorPicker {
    &__Container {
        display: flex;
        flex-direction: column;
        width: 300px;
        height: 292px;
        flex-shrink: 0;
        border-radius: 0 0 10px 10px;
        border: 1px solid $color-gray-300;
        background: $color-white;
        align-items: center;
    }

    &__Saturation {
        display: flex;
        width: 100%;
        height: 183px;
        position: relative;
    }

    &__Hue {
        width: 252px;
        height: 16px;
        position: relative;
        border-radius: 3px;
        overflow: hidden;
    }

    &__Hex {
        display: flex;
    }

    &__Swatch {
        width: 37px;
        height: 37px;
        background: var(--hex);
        border-radius: 5px 0 0 5px;
    }

    &__CustomSlider {
        width: 16px;
        height: 16px;
        z-index: 10;
        background: hsl(var(--hsl-h), 100%, 50%);
        border-radius: 50%;
        outline: 2px solid $color-white;
        outline-offset: -1px;
        position: absolute;
        transform: translateX(-50%);
    }
}

.ConsoleFooter {

    &__Container {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        background: $color-white;
    }

    &__Spacer {
        height: 150px;
    }
}