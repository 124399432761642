@import './guideVar.scss';

.ChatPreview {
    &__Container {
        width: 100%;
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 42px 58px;
    }

    &__Block {
        width: 390px; 
        height: 700px; 
        min-height: 700px;
        border-radius: 25px; 
        overflow: hidden; 
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
        background-image: url('/public/img/chatPreview/preview-block.png'); 
        background-size: contain; 
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__button {
            width: 262px;
            height: 52px;
            border: none;
            background: #e0e6f3;
            border-radius: 8px;
        }

        &__textWrap {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
        }

        &__iframeWrap {
            width: 100%;
            height: 100%;
            border-radius: 25px;
            overflow: hidden;
            box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);

            &__mobile{
                @extend .ChatPreview__Block__iframeWrap;
                width: 390px;
                min-height: 560px;
                max-height: 754px;
            }
        }

        &__iframe {
            width: 100%;
            height: 100%;
            border: none;
        }
    }
}

.AgentToggle {
    &__Container {
        width: 100%;
        height: 100%;
        position: relative;
        flex-grow: 1;
    }

    &__Wrap {
        position: absolute;
        top: 8px;
        right: 8px;
        width: fit-content;
        height: 37px;
        background: $color-gray-200;
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding: 4px;
    }

    &__active {
        display: flex;
        width: fit-content;
        height: 100%;
        padding: 8px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background: $color-white;
        cursor: pointer;
    }

    &__inactive {
        display: flex;
        width: fit-content;
        height: 100%;
        padding: 8px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background: $color-gray-200;
        cursor: pointer;

        p {
            color: $color-gray-500;
        }
    }
}