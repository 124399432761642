.Plans__Container {
  width: fit-content;
  min-width: fit-content;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
}
.Plans__Plan {
  width: 946px;
  max-width: 946px;
  min-width: 946px;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #efefef;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.Plans__PlanTitle {
  width: 100%;
  display: flex;
  padding: 80px 0px 40px 0px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}
.Plans__PlanBody {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}

.PlanToggle__Wrap {
  display: flex;
  width: fit-content;
  position: relative;
}
.PlanToggle__Switch {
  display: flex;
  flex-direction: row;
  padding: 12px 20px;
  align-items: center;
  gap: 8px;
  border-radius: 999px;
  background: #f6f6f6;
}
.PlanToggle__DiscountLabel {
  display: flex;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -53px;
  top: 14.5px;
  border-radius: 6px;
  background: #f2f4fa;
}

.PlanCards__Container {
  display: flex;
  width: 100%;
  padding: 0px 32px;
  align-items: center;
  gap: 8px;
}
.PlanCards__CateCard {
  display: flex;
  width: 25%;
  padding: 24px 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.PlanCards__Card {
  display: flex;
  width: 25%;
  padding: 24px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  border-radius: 10px;
  border: 1px solid #e1e1e1;
}
.PlanCards__PlanInfo__Wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}
.PlanCards__PlanInfo__Description {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.PlanCards__PlanInfo__Name {
  display: flex;
  height: 24px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.PlanCards__PlanInfo__Details {
  display: flex;
  align-items: flex-end;
  gap: 4px;
}
.PlanCards__PlanCta {
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 10px;
  background: #222222;
  cursor: pointer;
}
.PlanCards__PlanCta:hover {
  background: #666666;
}

.PlanTable__Container {
  display: flex;
  padding: 32px 32px 64px 32px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.PlanTable__Table__Wrap {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}
.PlanTable__Table__Title {
  display: flex;
  padding: 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 10px;
  background: #efefef;
}
.PlanTable__SubTable__Wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.PlanTable__SubTable__Title, .PlanTable__SubTable__Title__topBorder, .PlanTable__SubTable__Title__default {
  display: flex;
  padding: 16px;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid #e1e1e1;
}
.PlanTable__SubTable__Title__topBorder {
  border-top: 1px solid #e1e1e1;
}
.PlanTable__SubTable__Columns {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}
.PlanTable__SubTable__Items {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
}
.PlanTable__SubTable__Row {
  display: flex;
  width: 25%;
  height: 51px;
  padding: 16px;
  align-items: center;
  border-right: 1px solid #e1e1e1;
}
.PlanTable__SubTable__Item {
  display: flex;
  width: 25%;
  height: 51px;
  justify-content: center;
  align-items: center;
}

.CtaModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.CtaModal__Content {
  width: 33vw;
  height: 80vh;
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  min-width: 480px;
}
.CtaModal__Iframe {
  width: 100%;
  height: 100%;
  border: none;
  background-color: #ffffff;
  display: block;
  min-width: 480px;
}

.selectedPlan__title {
  background: #f6f6f6;
}
.selectedPlan__item {
  background: #f6f6f6;
}
.selectedPlan__cta {
  background: #e1e1e1;
  cursor: default;
}
.selectedPlan__cta:hover {
  background: #e1e1e1;
}

.Checkout__Wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  overflow: auto;
}
.Checkout__Container {
  width: 100%;
  max-width: 540px;
}
.Checkout__BtnWrap {
  width: 100%;
  padding: 0 24px;
}
.Checkout__Btn, .Checkout__Btn__primary {
  width: 100%;
  padding: 11px 22px;
  border: none;
  border-radius: 8px;
  background-color: #f2f4f6;
  color: #4e5968;
  font-weight: 600;
  font-size: 17px;
  cursor: pointer;
}
.Checkout__Btn__primary {
  background-color: #3282f6;
  color: #f9fcff;
}
.Checkout__Title {
  margin-top: 32px;
  margin-bottom: 0;
  color: #191f28;
  font-weight: bold;
  font-size: 24px;
}
.Checkout__Description {
  margin-top: 8px;
  color: #4e5968;
  font-size: 17px;
  font-weight: 500;
}
.Checkout__ResponseSection {
  width: 100%;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 20px;
}
.Checkout__ResponseLabel {
  font-weight: 600;
  color: #333d48;
  font-size: 17px;
}
.Checkout__ResponseText {
  font-weight: 500;
  color: #4e5968;
  font-size: 17px;
  padding-left: 16px;
  word-break: break-word;
  text-align: right;
}
.Checkout__ButtonGroup {
  width: 100%;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}

.Success__Confirmed__Wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 72px;
  width: 100%;
  max-width: 540px;
}
.Success__Loading__Wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 72px;
  height: 400px;
  justify-content: space-between;
  width: 100%;
  max-width: 540px;
}

.Fail__Wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 540px;
}

