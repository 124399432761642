.AgentCustom__Container {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  background: #ffffff;
  border-radius: 10px;
  position: relative;
}
.AgentCustom__Wrap {
  width: 100%;
  display: flex;
  justify-content: center;
}
.AgentCustom__Left {
  min-width: 455px;
  height: 735px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.AgentCustom__Right {
  width: 100%;
  max-width: 529px;
  min-width: 0;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 10px;
  overflow: visible;
  position: relative;
}
.AgentCustom__Hr {
  width: 100%;
  border: 1px solid #efefef;
}
.AgentCustom__ButtonWrap, .AgentCustom__ButtonWrap__betaFeatures, .AgentCustom__ButtonWrap__col {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: flex-end;
}
.AgentCustom__ButtonWrap__col {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.AgentCustom__ButtonWrap__betaFeatures {
  padding: 48px 0 12px 0;
}
.AgentCustom__Button, .AgentCustom__Button__Inactive {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 12px 20px;
  background: #154cca;
  border: none;
  border-radius: 10px;
}
.AgentCustom__Button__Inactive {
  background: #efefef;
}/*# sourceMappingURL=agent.module.css.map */