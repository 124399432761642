.DBLineGraphS__Container {
  display: flex;
  flex-direction: column;
}
.DBLineGraphS__TextWrap {
  display: flex;
  flex-direction: column;
  cursor: default;
}
.DBLineGraphS__Category {
  display: flex;
  align-items: center;
}
.DBLineGraphS__NoChartWrap {
  width: 100%;
  height: 182px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.DBLineGraphS__ChartWrap {
  width: 100%;
  min-width: 0;
  overflow: hidden;
  display: block;
}
.DBLineGraphS__GraphWrap {
  display: flex;
  flex-direction: column;
}
.DBLineGraphS__XAxis {
  width: 100%;
  color: #e1e1e1;
  height: 1px;
  background-color: #e1e1e1;
  border-width: 0px;
  margin-block: 0;
}
.DBLineGraphS__Dates {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: default;
}

.DBLineGraphL__Container {
  display: flex;
  flex-direction: column;
  position: relative;
}
.DBLineGraphL__NoChartWrap {
  width: 100%;
  height: 335px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.DBLineGraphL__Date, .DBLineGraphL__Date__right, .DBLineGraphL__Date__left {
  position: absolute;
  top: 313px;
  cursor: default;
}
.DBLineGraphL__Date__left {
  left: 53px;
}
.DBLineGraphL__Date__right {
  right: 0px;
}

.DBBarGraphL__Container {
  display: flex;
  flex-direction: column;
}
.DBBarGraphL__NoChartWrap {
  width: 100%;
  height: 495px;
  display: flex;
  align-items: center;
  justify-content: center;
}/*# sourceMappingURL=dashboardCharts.module.css.map */